export async function loadIndicatorData(selectedIndicator, datensatz, indicatorData, opt) {
    // pass indicatorData not with an $ so we can use the set method here
    // load all data for an indicator to display on the map
    // happens when the Datensatz changes or the indicator
    if (!opt.allowLoading) return;
    const res = await fetch(`/data/indicators/${datensatz}/${selectedIndicator}.json`);

    if (res.status !== 200) {
        indicatorData.set(false);

        console.warn('no data for this indicator');
    } else {
        indicatorData.set(await res.json());
    }
}



<script>
	import tooltip from '$lib/utils/tooltip';
	import { indicatorTypesInfo } from '$lib/stores/storesDashboard';
	import {
		selectedIndicatorGroup,
		selectedIndicator,
		indicatorListSiedlung,
		indicatorListVegetation,
		selectedDatensatz
	} from '$lib/stores/storesDashboard';
	import { selectedAdminData } from '$lib/stores/storesDashboard';

	let left = 'Siedlung';
	let right = 'Vegetation';

	$: filter =
		$selectedIndicatorGroup == 'Siedlung' ? $indicatorListSiedlung : $indicatorListVegetation;

	$: result = filter.map((item) => {
		return { key: item.value, value: $selectedAdminData[item.value], slug: item.slug };
	});

	function onSwitchIndicatorGroup(event) {
		$selectedIndicatorGroup = event.target.value;

		$selectedIndicatorGroup == 'Siedlung'
			? ($selectedIndicator = 'siedlung_baulich-gepraegt_ha-jahr')
			: ($selectedIndicator = 'vegetation_veraenderung-stadtgruen_ha-jahr');
	}

	function onClickIndicator(name) {
		$selectedIndicator = name;
	}

	function getNumberUnit(indicator) {
		return `${$indicatorTypesInfo[indicator.key].unit}`;
	}

	function getIndicatorLabel(indicator) {
		return $indicatorTypesInfo[indicator.key].altLabel &&
			$indicatorTypesInfo[indicator.key].altLabel[$selectedDatensatz]
			? $indicatorTypesInfo[indicator.key].altLabel[$selectedDatensatz]
			: $indicatorTypesInfo[indicator.key].label;
	}
</script>

<!-- Switch -->
<div class="switch flex w-full mt-4">
	<button
		class="flex-1 bg-gray-200 p-2 rounded-l flex justify-center cursor-pointer hover:bg-gray-300"
		class:active={$selectedIndicatorGroup === left}
		value={left}
		on:click={onSwitchIndicatorGroup}
	>
		<span class="icon-building mr-1 pointer-events-none" />
		<span class="pointer-events-none">{left}</span>
	</button>
	<button
		class="flex-1 bg-gray-200 p-2 rounded-r flex justify-center cursor-pointer hover:bg-gray-300"
		class:active={$selectedIndicatorGroup === right}
		value={right}
		on:click={onSwitchIndicatorGroup}
	>
		<span class="icon-vegetation mr-3 pointer-events-none" />
		<span class="pointer-events-none">{right}</span>
	</button>
</div>

<!-- List -->
<div class="indicator-tabs mt-4 space-y-2">
	{#if result[0] && result[0].value !== undefined}
		{#each result as indicator}
			<div
				class="bg-gray-200 p-2 rounded flex cursor-pointer hover:bg-gray-300"
				class:active={$selectedIndicator === indicator.key}
				class:inactive={indicator.value === 'nodata'}
				on:click={onClickIndicator(indicator.key)}
			>
				{#if indicator.value !== 'nodata'}
					<div class="indicator-value font-bold shrink-0 w-20 text-right">
						{indicator.value ? indicator.value.toLocaleString('de-DE') : indicator.value}
					</div>

					<div class="indicator-unit font-bold shrink-0 w-32 text-left pl-2">
						{getNumberUnit(indicator)}
					</div>
				{:else}
					<div class="indicator-unit font-bold shrink-0 w-52 text-left pl-2">keine Daten</div>
				{/if}

				<div class="indicator-name shrink-0 w-64">
					{getIndicatorLabel(indicator)}
					<a
						use:tooltip={{
							content: 'Weitere Informationen zum Indikator',
							placement: 'top'
						}}
						href="/indikatoren/{indicator.slug}"
						class="icon-info hover:text-white"
					/>
				</div>
			</div>
		{/each}
	{/if}
</div>

<style lang="scss">
	@import '../../../../styles/variables.scss';

	.active {
		background-color: $color-select;
		color: white;
	}

	.inactive {
		color: #b7b7b7;
		pointer-events: none;
	}
</style>

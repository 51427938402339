<script>
	import Chart from '$lib/main/dashboard/chart/Chart.svelte';
	import UnitSwitcher from '$lib/main/dashboard/infobar/UnitSwitcher.svelte';
	import {
		selectedAdminName,
		selectedAdminNameArt,
		selectedAdminDataYears,
		datasetUnits,
		selectedDatensatz
	} from '$lib/stores/storesDashboard';

	export let chartDataVerteilung;
	export let chartUnit;
	export let chartUnitSymbol;
	export let chartLabelWidth;
	export let headings;

	let units;
	let noDataAtAll = false;

	function checkIfNoData(d) {
		return d === 'nodata';
	}

	function onChange() {
		units = [
			{
				value: 'ha',
				label: 'ha',
				symbol: 'ha'
				// active: $datasetUnits[$selectedDatensatz].indexOf('ha') === -1 ? false : true
			},
			{
				value: 'prozent',
				label: '%',
				symbol: '%',
				disabled: $datasetUnits[$selectedDatensatz].indexOf('prozent') === -1 ? true : false
			}
		];
	}

	$: {
		noDataAtAll = chartDataVerteilung.values.every(checkIfNoData);
	}

	$: {
		if (chartDataVerteilung) {
			onChange();
		}
	}
</script>

<div class="text-left text-sm">
	<h4>Datengrundlage</h4>
	<div class="intro mb-4">
		Die vorgestellte Datengrundlage bezieht sich auf das Jahr
		<span class="font-bold">{$selectedAdminDataYears[1]}. </span>
	</div>

	{#if chartDataVerteilung}
		<div class="chart mt-6 bg-gray-100 p-4 rounded">
			<div class="font-bold mb-0">
				<div>{headings.classes}</div>
				<span class="active text-select font-bold">
					<span>{$selectedAdminName} {$selectedAdminNameArt}</span>
				</span>
			</div>

			{#if noDataAtAll}
				<br />
				<span>Keine Daten vorhanden</span>
			{:else}
				<UnitSwitcher bind:chartUnit bind:chartUnitSymbol {units} width="20" />
				<Chart bind:chartData={chartDataVerteilung} bind:chartUnitSymbol bind:chartLabelWidth />
			{/if}
		</div>
	{/if}
</div>

<style lang="scss">
	@import '../../../../styles/variables.scss';

	.active {
		color: $color-select;
	}
</style>

<script>
	// components
	import Sidebar from '$lib/layouts/sidebar/Sidebar.svelte';
	import SidebarElement from '$lib/layouts/sidebar/SidebarElement.svelte';
	import NavDatensaetze from '$lib/main/dashboard/navs/NavDatensaetze.svelte';
	import NavDatenansicht from '$lib/main/dashboard/navs/NavDatenansicht.svelte';
	import Map from '$lib/main/dashboard/map/Map.svelte';
	import SearchAdmin from '$lib/main/dashboard/search/SearchAdmin.svelte';
	import NavAdminTypes from '$lib/main/dashboard/navs/NavAdminTypes.svelte';
	import Center from '$lib/layouts/center/Center.svelte';
	import Infobar from '$lib/main/dashboard/infobar/Infobar.svelte';
	import TopNav from '$lib/layouts/sidebar/TopNav.svelte';
	import TopNavButton from '$lib/layouts/sidebar/TopNavButton.svelte';
	import InfobarButton from '$lib/main/dashboard/infobar/InfobarButton.svelte';
	import validateQuery from '$lib/utils/validateQuery';
	import query from 'query-store';
	import { isMobile } from '$lib/stores/storesShared';
	import { infoBarVisible } from '$lib/stores/storesDashboard';

	import { loadIndicatorData } from '$lib/utils/loadIndicatorData';
	import { loadAdminData } from '$lib/utils/loadAdminData';

	import { onMount } from 'svelte';

	import {
		visibleSidebarEl,
		selectedAdminId,
		selectedDatensatz,
		selectedIndicator,
		selectedAdminData,
		selectedDataView,
		dataViewTypes,
		indicatorData,
		expertModeVisible
	} from '$lib/stores/storesDashboard';

	import { datensaetze } from '$lib/stores/storesShared';

	import ExpertMode from './dashboard/expertmode/ExpertMode.svelte';

	let allowLoading = false;

	$: {
		loadAdminData($selectedAdminId, $selectedDatensatz, selectedAdminData, {
			allowLoading: allowLoading
		});
	}

	$: {
		loadIndicatorData($selectedIndicator, $selectedDatensatz, indicatorData, {
			allowLoading: allowLoading
		});
	}

	onMount(() => {
		$selectedDatensatz = validateQuery($query.mdatensatz, {
			type: 'isSpecificValue',
			fallback: $selectedDatensatz,
			validValues: Object.values($datensaetze)
				.map(function (d) {
					return Object.keys(d.subDatensaetze);
				})
				.flat()
		});
		// set the
		$selectedDataView = validateQuery($query.mdatensatzview, {
			type: 'isSpecificValue',
			fallback: $selectedDataView,
			validValues: Object.keys($dataViewTypes)
		});
		allowLoading = true;
		loadIndicatorData($selectedIndicator, $selectedDatensatz, indicatorData, {
			allowLoading: allowLoading
		});
		loadAdminData($selectedAdminId, $selectedDatensatz, selectedAdminData, {
			allowLoading: allowLoading
		});
	});

	let centerWidth;

	$: mapElementTranslateX =
		!$infoBarVisible && !$isMobile
			? `transform: translateX(350px) translateX(-9rem);`
			: 'transform: translateX(0px);';
</script>

{#if $expertModeVisible}
	<ExpertMode />
{/if}

<Sidebar position="absolute">
	<TopNav>
		<TopNavButton
			value="1"
			icon="icon-germany"
			iconSize="text-xl"
			color="#07253A"
			bind:bindedValue={$visibleSidebarEl}
		/>
		<TopNavButton
			value="2"
			icon="icon-database"
			iconSize="text-base"
			color="#516675"
			bind:bindedValue={$visibleSidebarEl}
		/>
		<TopNavButton
			value="3"
			icon="icon-sliders"
			iconSize="text-base"
			color="#9CA8B0"
			bind:bindedValue={$visibleSidebarEl}
		/>
	</TopNav>

	<SidebarElement
		title="Verwaltungseinheit wählen"
		value="1"
		bind:bindedValue={$visibleSidebarEl}
		icon="germany"
		iconSize="text-lg"
	>
		<NavAdminTypes />
	</SidebarElement>

	<SidebarElement
		title="Datensatz wählen"
		value="2"
		bind:bindedValue={$visibleSidebarEl}
		icon="database"
		iconSize="text-sm"
	>
		<NavDatensaetze />
	</SidebarElement>

	<SidebarElement
		title="Datenansicht wählen"
		value="3"
		bind:bindedValue={$visibleSidebarEl}
		icon="sliders"
		iconSize="text-sm"
	>
		<NavDatenansicht />
	</SidebarElement>
</Sidebar>

<Center>
	<span
		bind:clientWidth={centerWidth}
		class="search-wrapper lg:mt-5 z-10"
		style="transition: 500ms transform; {mapElementTranslateX}"
	>
		<SearchAdmin />
	</span>
	<Map
		mapId={0}
		showLayerSwitch={true}
		layerSwitchPosLeft="26rem"
		layerSwitchPosContent="30rem"
		showZoomControls={true}
		showOpacitySlider={true}
		opSliderPosLeft="26rem"
		opSliderPosContent="30rem"
		zoomNavPosLeft="26rem"
		mapScalePad="4"
		mapScalePosBottom="20"
		bind:selectedDatensatz={$selectedDatensatz}
		bind:indicatorData={$indicatorData}
	/>
	<InfobarButton />
</Center>

<Infobar />

<style lang="scss">
	.search-wrapper {
		left: calc(50vw - 9rem);
		position: absolute;
		z-index: 1;

		@media screen and (max-width: 1024px) {
			left: unset;
		}
	}
</style>

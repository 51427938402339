<script>
	import { fly } from 'svelte/transition';
	import OpacitySlider from '../slider/OpacitySlider.svelte';
	import { mapOpacitySliderVisible } from '$lib/stores/storesDashboard';
	import { mapLayerSwitcherVisible } from '$lib/stores/storesDashboard';

	export let posLeft;
	export let posLeftReveal;
	import { isMobile } from '$lib/stores/storesShared';

	$: left = $isMobile ? '1rem' : posLeft;
	$: leftReveal = $isMobile ? '5rem' : posLeftReveal;

	function onClick() {
		$mapOpacitySliderVisible = !$mapOpacitySliderVisible;
		$mapLayerSwitcherVisible = false;
	}
</script>

<div
	on:click={onClick}
	class="icon-sliders bg-white rounded cursor-pointer p-4 absolute top-52 lg:top-50 border font-sans text-md"
	style="left: {left}"
/>

{#if $mapOpacitySliderVisible}
	<div
		class="absolute bg-white rounded top-52 lg:top-50 p-4 text-sm space-y-1 border z-20 shadow-lg lg:shadow-none"
		transition:fly|local={{ x: -10, duration: 300 }}
		style="left: {leftReveal}"
	>
		<OpacitySlider />
	</div>
{/if}

<style lang="scss">
	@import '../../../styles/variables.scss';

	.icon-sliders {
		z-index: 1;
	}
</style>

const validators = {
	mapCenter: function (val) {
		const center = val.split('-');
		if (center.length === 2 && Number(center[0]) && Number(center[1])) {
			return [Number(center[0]), Number(center[1])];
		} else {
			return false;
		}
		return val;
	},
	mapZoom: function (val) {
		if (Number(val) && val >= 0 && val <= 25) {
			return val;
		} else {
			return false;
		}
	},
	isSpecificValue: function (val, opt) {
		if (opt.validValues.indexOf(val) !== -1) {
			return val;
		} else {
			return false;
		}
	}
};

export default function (queryValue, opt) {
	if (!queryValue) {
		return opt.fallback;
	}

	return validators[opt.type](queryValue, opt) || opt.fallback;
}

<script>
	import tooltip from '$lib/utils/tooltip';
	// import selectedDataView from '$lib/stores/storesDashboard';
	import { selectedDataView } from '$lib/stores/storesDashboard';

	export let dataset;
	export let years;
</script>

<div
	class="dataset-info bg-white rounded absolute z-10 top-4 right-2 h-10 text-sm font-bold p-2 text-center flex items-center"
>
	<span class="pr-1">
		{#if $selectedDataView !== 'klassen'}
			{years[0]} –
		{/if}
		{years[1]}</span
	>
	<a
		use:tooltip={{
			content: 'Weitere Informationen zum Datensatz',
			placement: 'top'
		}}
		target="_blank"
		href="/daten/{dataset}"
		class="icon-info hover:text-select"
	/>
</div>

<script>
	import Chart from '$lib/main/dashboard/chart/Chart.svelte';
	import { prepairVerteilung, prepairVeraenderung } from '$lib/utils/prepairChartData';
	import { getDatensatzHeadings } from '$lib/utils/getDatensatzHeadings';
	import { getChartDataIndicator } from '$lib/utils/getChartDataIndicator';

	import {
		classTypes,
		datensatzLabelWidth,
		selectedDataView,
		selectedAdminId,
		selectedAdminName,
		lookupTable,
		reverseLookup,
		selectedIndicator,
		indicatorTypesInfo,
		datasetUnits
	} from '$lib/stores/storesDashboard';

	import { datensaetze } from '$lib/stores/storesShared';

	export let selectedAdminData;
	export let selectedAdminDataYears;
	export let selectedDatensatz;

	export let chartUnit;
	export let chartUnitSymbol;

	let chartData;
	let chartLabelWidth = 120;
	let headings = {
		change: '',
		classes: ''
	};
	let noDataForUnit;

	function needsChartDataUpdate() {
		if ($selectedDataView === 'analyse') {
			getChartDataIndicator(
				$selectedAdminId,
				selectedAdminData,
				$selectedAdminName,
				selectedDatensatz,
				$lookupTable,
				$reverseLookup,
				chartUnit
			).then(function (data) {
				chartData = data;
			});
		}
		if ($selectedDataView === 'klassen') {
			chartData = prepairVerteilung(
				selectedAdminData,
				selectedAdminDataYears,
				chartUnit,
				$classTypes,
				selectedDatensatz
			);
		}

		if ($selectedDataView === 'veraenderung') {
			chartData = prepairVeraenderung(
				selectedAdminData,
				selectedAdminDataYears,
				chartUnit,
				$classTypes,
				selectedDatensatz
			);
		}

		chartLabelWidth = getLabelWidth();
	}

	$: {
		// when the chart unit changes or the admin data or the data view
		chartUnit;
		selectedAdminData;
		$selectedDataView;
		needsChartDataUpdate();
	}

	function getLabelWidth() {
		if ($selectedDataView === 'analyse') {
			return 130;
		} else {
			return $datensatzLabelWidth[selectedDatensatz] || 120;
		}
	}

	$: {
		chartLabelWidth = getLabelWidth();
	}

	$: {
		headings = getDatensatzHeadings(selectedDatensatz, $datensaetze);
	}

	$: {
		noDataForUnit = $datasetUnits[selectedDatensatz].indexOf(chartUnit) === -1;
	}

	$: indicatorLabel =
		$indicatorTypesInfo[$selectedIndicator].altLabel &&
		$indicatorTypesInfo[$selectedIndicator].altLabel[selectedDatensatz]
			? $indicatorTypesInfo[$selectedIndicator].altLabel[selectedDatensatz]
			: $indicatorTypesInfo[$selectedIndicator].label;
</script>

<div class="text-sm p-6 w-2/3 bg-gray-100 p-2 rounded text-left">
	<div class="mb-4 font-bold text-text">
		{#if $selectedDataView === 'analyse'}
			{indicatorLabel}
		{:else if $selectedDataView === 'veraenderung'}
			{headings.change}
		{:else if $selectedDataView === 'klassen'}
			{headings.classes}
		{/if}
	</div>

	{#if chartData && chartData.values.length}
		{#if $selectedDataView === 'analyse' && $selectedAdminId === null}
			<span class="text"
				><span class="text-select text-sm">Deutschland gesamt:</span>
				<span
					>{chartData.values[0] === 'nodata' ? 'keine Daten verfügbar' : chartData.values[0]}</span
				></span
			>
		{:else}
			<Chart bind:chartData bind:chartUnitSymbol bind:chartLabelWidth compactMode={true} />
		{/if}
	{:else if noDataForUnit}
		Keine Daten für die Einheit "{chartUnitSymbol}" verfügbar
	{:else}
		Keine Daten vorhanden
	{/if}
</div>

<style>
	.text {
		font-size: 13px;
	}
</style>

<script>
	import { mapLayerOpacity, selectedDataView } from '$lib/stores/storesDashboard';
	export let mapColorLegend;
	export let mapScalePad;
	export let mapScalePosBottom;
	export let normaliseColoring;
	export let allowNormalising = false;
	export let unit;
	export let translateX;

	import tooltip from '$lib/utils/tooltip';
</script>

{#if mapColorLegend && $selectedDataView === 'analyse'}
	<div
		class="wrapper z-10 w-72 lg:w-96 absolute bottom-6 lg:bottom-{mapScalePosBottom} bg-white rounded p-{mapScalePad}"
		style="transition: 500ms transform; {translateX}"
	>
		<div class="flex justify-center">
			{#each mapColorLegend as c, i}
				{#if mapColorLegend[i].value[0] !== undefined}
					<div class="text-text text-xs text-right flex justify-between pb-1">
						{#if i != 0}
							<span>{mapColorLegend[i - 1].value[1].toLocaleString('de-DE')}</span>
						{/if}
					</div>
				{/if}
			{/each}
		</div>
		<div class="flex">
			{#each mapColorLegend as c, i}
				{#if mapColorLegend[i].value[0] !== undefined}
					<div class="h-3 flex" style="background-color:{c.color}; opacity:{$mapLayerOpacity}" />
				{/if}
			{/each}
		</div>
		<div class="text-text text-xs text-right pt-1">{unit}</div>
		{#if allowNormalising}
			<div
				use:tooltip={{
					content: 'Für beide Datensätze die gleiche Farbskala verwenden (ein/aus)',
					placement: 'top'
				}}
				class="normalise-btn rounded p-2 icon-exchange flex items-center justify-center"
				class:active={normaliseColoring}
				on:click={() => (normaliseColoring = !normaliseColoring)}
			/>
		{/if}
	</div>
{/if}

<style lang="scss">
	@import '../../../styles/variables.scss';

	.wrapper {
		left: calc(50% - 12rem);
		// pointer-events: none;

		@media screen and (max-width: 1024px) {
			left: calc(50% - 9rem);
		}

		div {
			flex: 1;
		}

		.text-text {
			span {
				left: -2px;
				position: relative;
			}
		}

		.normalise-btn {
			position: absolute;
			right: -55px;
			width: 48px;
			height: 48px;
			background-color: white;
			top: 0px;
			cursor: pointer;
			text-align: center;

			&.active {
				background-color: $color-select;
				color: white;
			}
		}
	}

	// .tippy-tooltip[data-template-id='tippy-10'] {
	// 	text-align: center;
	// }
</style>

export async function getChartDataIndicator(
	selectedAdminId,
	selectedAdminData,
	selectedAdminName,
	selectedDatensatz,
	lookupTable,
	reverseLookup,
	chartUnit
) {
	const chartDataTemp = {
		values: [],
		labels: [],
		backgroundColor: []
	};
	const fetches = [];

	// Data for selected admin
	if (selectedAdminId !== null) {
		chartDataTemp.values.push(selectedAdminData[chartUnit]);
		chartDataTemp.labels.push(selectedAdminName);
	}

	// add fetch query for Germany to fetch in Promise all
	fetches.push(fetch(`/data/admins/${selectedDatensatz}/-1.json`));


	// Check and get the type of Kreis - then add it to fetch if applicable
	const kreisArt =
	lookupTable[selectedAdminId] &&
	lookupTable[selectedAdminId]['art_gebietseinheit'] === 'Kreis'
		? lookupTable[selectedAdminId]['kategorie_gebietseinheit']
		: false;
	if (kreisArt) {
		fetches.push(
			fetch(`/data/admins/${selectedDatensatz}/${reverseLookup[kreisArt]}.json`)
		);
	}

	// Check and get the type of Gemeindeverband - then add it to fetch if applicable
	const gemeindeverbandArt =
		lookupTable[selectedAdminId] &&
		lookupTable[selectedAdminId]['art_gebietseinheit'] === 'Gemeindeverband'
			? lookupTable[selectedAdminId]['kategorie_gebietseinheit']
			: false;
	if (gemeindeverbandArt) {
		fetches.push(
			fetch(`/data/admins/${selectedDatensatz}/${reverseLookup[gemeindeverbandArt]}.json`)
		);
	}

	return Promise.all(fetches)
		.then(function (responses) {
			// Get a JSON object from each of the responses
			return Promise.all(
				responses.map(function (response) {
					return response.json();
				})
			);
		})
		.then(function (data) {
			
			// Data Avg Germany
			chartDataTemp.values.push(data[0][chartUnit]);
			chartDataTemp.labels.push('Ø Deutschland');

			// Add Avg Kreis Art if Kreis are selected as admin tyoe
			if (kreisArt) {
				chartDataTemp.values.push(data[1][chartUnit]);
				chartDataTemp.labels.push(kreisArt);
			}

			// Add Avg Gemeindeverband Art if Gemeindeverbände are selected as admin tyoe
			if (gemeindeverbandArt) {
				chartDataTemp.values.push(data[1][chartUnit]);
				chartDataTemp.labels.push(gemeindeverbandArt);
			}

			chartDataTemp.values.forEach(() => {
				chartDataTemp.backgroundColor.push('#037aff');
			});

			return chartDataTemp;
		})
		.catch(function (error) {
			// if there's an error, log it
			console.warn(error);
		});
}

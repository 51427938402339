export async function loadAdminData(selectedAdminId, datensatz,selectedAdminData,opt) {
    // load the data for an admin area
    // happens when an selectedAdminId changes
    // when the selectedAdminId is null (Germany) take the data from the Admin Type
    if (!opt.allowLoading) return; // load from top level?

    const dataId = selectedAdminId || '-1';

    const res = await fetch(`/data/admins/${datensatz}/${dataId}.json`);

    if (res.status !== 200) {
        selectedAdminData.set(false);
        console.warn('no admin data found for this request');
    } else {
        selectedAdminData.set(await res.json());
    }
}

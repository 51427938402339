export function getDatensatzHeadings(selectedDatensatz, datensaetze) {
    let headings = {
        classes: '',
        change: ''
    };
    headings.classes = Object.values(datensaetze).filter((item) => {
        return item.subDatensaetze[selectedDatensatz];
    })[0].subDatensaetze[selectedDatensatz].headingChartClasses;

    headings.change = Object.values(datensaetze).filter((item) => {
        return item.subDatensaetze[selectedDatensatz];
    })[0].subDatensaetze[selectedDatensatz].headingChartChange;    
    return headings;
}

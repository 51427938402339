<script>
	import { mapSelectedBackgroundLayer, mapBackgroundLayers, mapBackgroundLayersNames } from '$lib/stores/storesDashboard';
	import { mapLayerSwitcherVisible } from '$lib/stores/storesDashboard';
	import { mapOpacitySliderVisible } from '$lib/stores/storesDashboard';
	import { fly } from 'svelte/transition';
	import { isMobile } from '$lib/stores/storesShared';

	export let posLeft;
	export let posLeftReveal;

	$: left = $isMobile ? '1rem' : posLeft;
	$: leftReveal = $isMobile ? '5rem' : posLeftReveal;

	function onClick() {
		$mapLayerSwitcherVisible = !$mapLayerSwitcherVisible;
		$mapOpacitySliderVisible = false;
	}
</script>

<div
	on:click={onClick}
	class="icon-layers bg-white rounded cursor-pointer p-4 absolute top-36 lg:top-34 border font-sans text-md"
	style="left: {left}"
/>

{#if $mapLayerSwitcherVisible}
	<ul
		class="absolute bg-white rounded top-36 lg:top-34 p-4 text-sm space-y-1 w-60 lg:w-40 border z-20 shadow-lg lg:shadow-none"
		style="left: {leftReveal}"
		transition:fly|local={{ x: -10, duration: 300 }}
	>
		<!-- {#each Object.keys($mapBackgroundLayers) as key, i}
			<li
				class="cursor-pointer"
				class:active={$mapSelectedBackgroundLayer === key}
				on:click={() => {
					$mapSelectedBackgroundLayer = key;
				}}
			>	
				{$mapBackgroundLayers[key].label}
			</li>
		{/each} -->
		{#each Object.keys($mapBackgroundLayersNames) as key, i}
			<li
				class="cursor-pointer"
				class:active={$mapSelectedBackgroundLayer === key}
				style="text-indent: -1em; margin-left: 1em;"
				on:click={() => {
					$mapSelectedBackgroundLayer = key;
				}}
			>	
				{$mapBackgroundLayersNames[key]}
			</li>
		{/each}
	</ul>
{/if}

<style lang="scss">
	@import '../../../styles/variables.scss';
	div {
		ul {
			.active {
				color: $color-select;
				font-weight: 700;
			}
		}
	}

	.icon-layers {
		z-index: 1;
	}
</style>

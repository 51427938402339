<script>
	import {
		selectedAdminName,
		selectedAdminNameArt,
		selectedAdminDataYears,
		datasetUnits,
		selectedDatensatz
	} from '$lib/stores/storesDashboard';

	import UnitSwitcher from '$lib/main/dashboard/infobar/UnitSwitcher.svelte';
	import Chart from '$lib/main/dashboard/chart/Chart.svelte';

	let units;
	let hasData = false;
	let noDataAtAll = false;

	export let chartDataVeraenderung;
	export let chartUnit;
	export let chartUnitSymbol;
	export let chartLabelWidth;
	export let headings;

	function checkIfZero(d) {
		return d === 0;
	}

	function checkIfNoData(d) {
		return d === 'nodata';
	}

	function onChange() {
		units = [
			{
				value: 'ha',
				label: 'ha',
				symbol: 'ha'
				// active: $datasetUnits[$selectedDatensatz].indexOf('ha') === -1 ? false : true
			},
			{
				value: 'prozent',
				label: '%',
				symbol: '%',
				disabled: $datasetUnits[$selectedDatensatz].indexOf('prozent') === -1 ? true : false
			}
		];
	}

	$: {
		hasData = !chartDataVeraenderung.values.every(checkIfZero);
	}

	$: {
		noDataAtAll = chartDataVeraenderung.values.every(checkIfNoData);
	}

	$: {
		if (chartDataVeraenderung) {
			onChange();
		}
	}
</script>

<div class="text-left text-sm">
	<h4>Veränderung</h4>
	<div class="intro mb-4">
		Die vorgestellten Veränderung bezieht sich auf die Differenz zwischen den Jahren
		<span class="font-bold"> {$selectedAdminDataYears[0]} und {$selectedAdminDataYears[1]}. </span>
	</div>

	{#if chartDataVeraenderung}
		{#if hasData}
			<div class="chart mt-6 bg-gray-100 p-4 rounded">
				<div class="font-bold mb-0">
					<div>{headings.change}</div>
					<span class="active text-select font-bold">
						<span>{$selectedAdminName} {$selectedAdminNameArt}</span>
					</span>
				</div>

				{#if noDataAtAll}
					<br />
					<span>Keine Daten vorhanden</span>
				{:else}
					<UnitSwitcher bind:chartUnit bind:chartUnitSymbol {units} width="20" />
					<Chart bind:chartData={chartDataVeraenderung} bind:chartUnitSymbol bind:chartLabelWidth />
				{/if}
			</div>
		{:else}
			<span>Keine Veränderung in</span>
			<span class="active text-select font-bold">
				{$selectedAdminName}
				{$selectedAdminNameArt}
			</span>
		{/if}
	{/if}
</div>

<style lang="scss">
	@import '../../../../styles/variables.scss';
</style>

<script>
	import List from '$lib/main/dashboard/infobar/indikatoren/List.svelte';
	import ChartIndikator from '$lib/main/dashboard/infobar/indikatoren/ChartIndikator.svelte';
	import UnitSwitcher from '$lib/main/dashboard/infobar/UnitSwitcher.svelte';
	import {
		selectedIndicator,
		indicatorTypesInfo,
		selectedAdminData,
		selectedAdminDataYears,
		selectedAdminName,
		selectedAdminNameArt,
		selectedAdminId,
		selectedDatensatz
	} from '$lib/stores/storesDashboard';
	export let chartLabelWidth;
	let chartUnit;
	let units = [];
	let chartUnitSymbol;

	$: indicatorLabel =
		$indicatorTypesInfo[$selectedIndicator].altLabel &&
		$indicatorTypesInfo[$selectedIndicator].altLabel[$selectedDatensatz]
			? $indicatorTypesInfo[$selectedIndicator].altLabel[$selectedDatensatz]
			: $indicatorTypesInfo[$selectedIndicator].label;

	function onSelectedIndicatorChange(selectedIndicator) {
		units = [];
		const indicatorName = selectedIndicator.split('_')[0] + '_' + selectedIndicator.split('_')[1];
		for (const key in $indicatorTypesInfo) {
			if (key.indexOf(indicatorName + '_') !== -1) {
				units.push({
					value: key,
					label: $indicatorTypesInfo[key].unit,
					symbol: $indicatorTypesInfo[key].symbol
				});
			}
		}
		chartUnit = selectedIndicator;
		chartUnitSymbol = $indicatorTypesInfo[selectedIndicator].symbol;
	}

	$: {
		onSelectedIndicatorChange($selectedIndicator);
	}
</script>

<div class="text-left text-sm">
	<h4>Indikatoren</h4>
	<div class="intro">
		Die hier vorgestellten Indikatoren beziehen sich auf die Differenz aus den Jahren
		<span class="font-bold"> {$selectedAdminDataYears[0]} und {$selectedAdminDataYears[1]}.</span>
		<br />
		Die Zahlen stellen die jeweiligen Veränderungswerte zwischen beiden Jahren dar.
	</div>
	<div class="font-bold text-select  mt-2">{$selectedAdminName} {$selectedAdminNameArt}</div>

	<List />

	{#if $selectedAdminId && $selectedAdminData.schluessel_gebietseinheit !== -1}
		<div class="chart mt-6 rounded">
			<div class="chart bg-gray-100 p-4 rounded">
				<h4 class="mt-2">{indicatorLabel}</h4>
				{#if units.length === 1}
					<p>(in {$indicatorTypesInfo[$selectedIndicator].unit})</p>
				{/if}
				<UnitSwitcher bind:chartUnit bind:chartUnitSymbol {units} width="full" />
				{#if $selectedAdminData[chartUnit] === 'nodata'}
					<div class="chart text-center">keine Daten verfügbar</div>
				{:else}
					<ChartIndikator bind:chartUnit bind:chartUnitSymbol bind:chartLabelWidth />
				{/if}
			</div>
		</div>
	{/if}
</div>

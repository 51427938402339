<script>
	import Chart from '$lib/main/dashboard/chart/Chart.svelte';

	import { onMount } from 'svelte';

	import {
		lookupTable,
		selectedAdminData,
		selectedAdminId,
		selectedAdminName,
		reverseLookup,
		selectedDatensatz
	} from '$lib/stores/storesDashboard';
	import { getChartDataIndicator } from '$lib/utils/getChartDataIndicator';

	let chartData;
	export let chartUnit;
	export let chartUnitSymbol;
	export let chartLabelWidth;

	function prepairChartData() {
		getChartDataIndicator(
			$selectedAdminId,
			$selectedAdminData,
			$selectedAdminName,
			$selectedDatensatz,
			$lookupTable,
			$reverseLookup,
			chartUnit
		).then(function (data) {
			chartData = data;
		});
	}

	$: {
		chartUnit; // when the units change - $indicatorData chnage is handle here too
		$selectedAdminData;
		prepairChartData();
	}

	onMount(() => {
		prepairChartData();
	});
</script>

{#if chartData}
	<Chart bind:chartData bind:chartUnitSymbol bind:chartLabelWidth />
{/if}

<script>
	import { createEventDispatcher } from 'svelte';
	import Select from 'svelte-select';
	export let value;
	// export let startLabel;
	export let width;
	export let icon;
	export let isDisabled = false;
	export let items = ['One', 'Two', 'Three'];
	export let noOptionsMessage;
	export let group = false;

	let isListOpen = false;
	const dispatch = createEventDispatcher();

	function findSelectedItem(val, items) {
		let selectedItem;
		items.forEach((item) => {
			if (item.value === val) {
				selectedItem = item;
			}
		});
		return selectedItem;
	}

	function handleSelect(event) {
		value = event.detail.value;
		if (isListOpen) {
			// only fire the select if the user has changed
			dispatch('valueChange', value);
		}
	}

	const groupBy = (item) => item.group;

	let startLabel = findSelectedItem(value, items);

	function onValueChange(value, items) {
		startLabel = findSelectedItem(value, items);
	}

	$: {
		onValueChange(value, items);
	}
</script>

<div class="icon" style="width:{width}rem;">
	<Select
		{items}
		Icon={icon}
		on:select={handleSelect}
		bind:noOptionsMessage
		showChevron={true}
		groupBy={group ? groupBy : false}
		{isDisabled}
		value={startLabel}
		isClearable={false}
		showIndicator={true}
		bind:listOpen={isListOpen}
	/>
</div>

<style lang="scss">
	@import '../../../styles/variables.scss';
	.icon {
		--selectedItemPadding: 0 10px 0 8px;
		--inputPadding: 0 10px 0 40px;
	}

	// :global(.selectedItem) {
	// 	color: $color-select;
	// }

	:global(.listContainer) {
		z-index: 30 !important;
	}

	:global(.indicator) {
		top: 7px !important;
	}
</style>

<script>
	import { dataViewTypes, selectedDataView, mapLayerOpacity } from '$lib/stores/storesDashboard';
	import { formatPercent } from '$lib/utils/formatNumbers';
	import query from 'query-store';

	function onSelect(selected) {
		$selectedDataView = selected;
		$query.mdatensatzview = selected;
	}
</script>

<div class="p-4 lg:p-0">
	<div class="switch flex flex-col w-full space-y-2 mb-4">
		{#each Object.values($dataViewTypes) as sublist, i}
			<div
				class="flex-1 bg-gray-200 p-2 rounded flex justify-center cursor-pointer hover:bg-gray-300"
				class:active={Object.keys($dataViewTypes)[i] === $selectedDataView}
				on:click={onSelect(Object.keys($dataViewTypes)[i])}
			>
				<span class="icon-{sublist.icon} pr-1" />
				<span>{sublist.label}</span>
			</div>
		{/each}
	</div>
</div>

<style lang="scss">
	@import '../../../styles/variables.scss';

	.active {
		background-color: $color-select;
		color: white;
	}
</style>

function filterByStringSegements(segs, selectedAdminData) {
	let classData = Object.keys(selectedAdminData);
	segs.forEach((seg) => {
		classData = classData.filter((key) => key.includes(seg));
	});
	return classData.reduce((cur, key) => {
		return Object.assign(cur, { [key]: selectedAdminData[key] });
	}, {});
}

function getClassKeyNames(classData) {
	return Object.keys(classData).map((d) => {
		const keyNameString = d.split('_');
		let key = keyNameString[2];

		if (keyNameString.length === 5) {
			key += '_';
			key += d.split('_')[3];
		}

		return key;
	});
}

function sortObj(obj) {
	return Object.keys(obj)
		.sort()
		.reduce(function (result, key) {
			result[key] = obj[key];
			return result;
		}, {});
}

function sortByAnotherArray(inArray, sortByArray) {
	// the labels get sorted alphabetical and with them the data from the inArray
	const reference_object = {};
	for (var i = 0; i < inArray.length; i++) {
		reference_object[sortByArray[i]] = inArray[i];
	}
	return Object.values(sortObj(reference_object));
}


function getLabels(keyNames,classTypes,selectedDatensatz){
    return keyNames.map((key) => {
		return classTypes[selectedDatensatz] && classTypes[selectedDatensatz][key]
			? classTypes[selectedDatensatz][key].label
			: 'MISSING LABEL';
	});
}

function getColors(keyNames,classTypes,selectedDatensatz){
    return keyNames.map((key) => {
		return classTypes[selectedDatensatz] && classTypes[selectedDatensatz][key]
			? classTypes[selectedDatensatz][key].color
			: '#000';
	});
}


function getClassData(selectedAdminData){
    return Object.keys(selectedAdminData)
		.filter((key) => key.includes('class_'))
		.reduce((cur, key) => {
			return Object.assign(cur, { [key]: selectedAdminData[key] });
		}, {});
}

export function prepairVerteilung(
	selectedAdminData,
	selectedAdminDataYears,
	chartUnit,
	classTypes,
	selectedDatensatz
) {
	const classData = getClassData(selectedAdminData)
	const classDataYearLast = filterByStringSegements(
		['_' + selectedAdminDataYears[1], '_' + chartUnit],
		classData
	);
	const keyNames = getClassKeyNames(classDataYearLast);	
	const labels = getLabels(keyNames,classTypes,selectedDatensatz);
	const colors = getColors(keyNames,classTypes,selectedDatensatz);
    
	return {
		values: sortByAnotherArray(Object.values(classDataYearLast), labels),
		labels: sortByAnotherArray(labels, labels),
		backgroundColor: sortByAnotherArray(colors, labels)
	};
}

export function prepairVeraenderung(
	selectedAdminData,
	selectedAdminDataYears,
	chartUnit,
	classTypes,
	selectedDatensatz
) {
	const classData = getClassData(selectedAdminData)
	const classDataYearLast = filterByStringSegements(
		['_diff', '_' + chartUnit],
		classData
	);
	const classDataDiff = filterByStringSegements(['_diff', '_' + chartUnit], classData);
	
	const keyNames = getClassKeyNames(classDataYearLast);
	const labels = getLabels(keyNames,classTypes,selectedDatensatz);
	const colors = getColors(keyNames,classTypes,selectedDatensatz);
	
	return {
		values: sortByAnotherArray(Object.values(classDataDiff), labels),
		labels: sortByAnotherArray(labels, labels),
		backgroundColor: sortByAnotherArray(colors, labels)
	};
}




<script>
	// https://maplibre.org/maplibre-gl-js-docs/api/
	import '../../../../../node_modules/mapbox-gl/src/css/mapbox-gl.css';

	import { onMount } from 'svelte';
	import mapboxgl from 'mapbox-gl';
	import mapStyle from './map-style';
	// import { scaleQuantize } from 'd3-scale';
	// import { scaleQuantile } from 'd3-scale';
	import scaleCluster from 'd3-scale-cluster';
	import query from 'query-store';
	import validateQuery from '$lib/utils/validateQuery';
	import { classTypes } from '$lib/stores/storesDashboard';
	import { formatTwoDecimals } from '$lib/utils/formatNumbers';
	import Spinner from '$lib/main/dashboard/spinner/Spinner.svelte';

	// https://observablehq.com/@d3/quantile-quantize-and-threshold-scales

	import { isMobile } from '$lib/stores/storesShared';
	import {
		lookupTable,
		selectedAdminType,
		selectedAdminId,
		infoBarVisible,
		selectedIndicator,
		mapCenter,
		mapZoom,
		mapSelectedBackgroundLayer,
		basemapSessionCookie,
		mapBackgroundLayers,
		mapLayerOpacity,
		selectedDataView,
		veraenderungsLayers,
		klassenLayers,
		mapLayerSwitcherVisible,
		mapOpacitySliderVisible,
		mobileSearchVisible,
		visibleSidebarEl,
		selectedAdminDataYears,
		expertModeVisible,
		indicatorTypesInfo
	} from '$lib/stores/storesDashboard';

	import MapLayerSwitcher from './MapLayerSwitcher.svelte';

	import MapScale from './MapScale.svelte';
	import MapOpacitySlider from './MapOpacitySlider.svelte';

	export let mapId;
	export let showLayerSwitch;
	export let showZoomControls;
	export let showOpacitySlider;
	export let zoomNavPosLeft;
	export let layerSwitchPosLeft;
	export let layerSwitchPosContent;
	export let opSliderPosLeft;
	export let opSliderPosContent;
	export let selectedDatensatz;
	export let indicatorData;
	export let indicatorDataFromMain;
	export let mapScalePad;
	export let mapScalePosBottom;
	export let opacitySlider;
	export let scrollZoom = true;
	export let allowNormalising = false;
	let normaliseColoring = false;
	let mapWidth;

	function filterObject(obj, type) {
		const asArray = Object.entries(obj);
		const filtered = asArray.filter(([key, value]) => typeof value === type);
		const values = Object.fromEntries(filtered);
		if (filtered.length === 0) {
			return [0];
		} else {
			return values;
		}
	}

	// copied from here: https://www.npmjs.com/package/mapbox-gl-utils
	function setLayerSource(layerId, source, sourceLayer) {
		const oldLayers = map.getStyle().layers;
		const layerIndex = oldLayers.findIndex((l) => l.id === layerId);
		const layerDef = oldLayers[layerIndex];
		const before = oldLayers[layerIndex + 1] && oldLayers[layerIndex + 1].id;
		layerDef.source = source;
		if (sourceLayer) {
			layerDef['source-layer'] = sourceLayer;
		}
		map.removeLayer(layerId);
		map.addLayer(layerDef, before);
	}

	function getWMSInfo(e) {
		if (popup) {
			popup.remove();
		}

		const base = $veraenderungsLayers[selectedDatensatz]
			? $veraenderungsLayers[selectedDatensatz].queryBase
			: false;
		if (!base) return;

		const bounds = map.getBounds();
		const bbox = `&bbox=${bounds._sw.lng}%2C${bounds._sw.lat}%2C${bounds._ne.lng}%2C${bounds._ne.lat}`;
		const width = `&width=${Math.round(map.getContainer().getBoundingClientRect().width)}`;
		const height = `&height=${Math.round(map.getContainer().getBoundingClientRect().height)}`;
		const point = `&x=${Math.round(e.point.x)}&y=${Math.round(e.point.y)}`;

		const queryString = `${base}${bbox}${width}${height}${point}`;

		fetch(queryString)
			.then((response) => response.json())
			.then((data) => {
				if (data && data.features && data.features[0] && data.features[0].properties) {
					const props = data.features[0].properties;
					const size = props.size || props.Size || props.area_ha;
					const selectedSize = formatTwoDecimals(size) > 0.001 ? formatTwoDecimals(size) : '< 0.00';

					let htmlContent = '';
					const hasNoComparison = props.change;

					if (hasNoComparison) {
						const label = $classTypes[selectedDatensatz][props.change]
							? $classTypes[selectedDatensatz][props.change].label
							: '?';
						const color = $classTypes[selectedDatensatz][props.change]
							? $classTypes[selectedDatensatz][props.change].color
							: '#000';
						htmlContent = `<div class='p-2 text-sm space-y-1'>
								<h4>Veränderung: ${selectedSize} ha </h4>
								<div class='text-sm flex items-center pt-2'>
										<span class="circle" style="background-color:${color}; opacity:${$mapLayerOpacity}"></span>
										<span><b>${$selectedAdminDataYears[1] || '?'}</b>: ${label}</span> 
								</div>`;
					} else {
						const labelFirst = $classTypes[selectedDatensatz][props.first]
							? $classTypes[selectedDatensatz][props.first].label
							: $classTypes[selectedDatensatz]['bebaute-flaechen_' + props.first]
							? $classTypes[selectedDatensatz]['bebaute-flaechen_' + props.first].label
							: '?';
						const colorFirst = $classTypes[selectedDatensatz][props.first]
							? $classTypes[selectedDatensatz][props.first].color
							: $classTypes[selectedDatensatz]['bebaute-flaechen_' + props.first]
							? $classTypes[selectedDatensatz]['bebaute-flaechen_' + props.first].color
							: '#000';
						const labelLast = $classTypes[selectedDatensatz][props.last]
							? $classTypes[selectedDatensatz][props.last].label
							: $classTypes[selectedDatensatz]['bebaute-flaechen_' + props.last]
							? $classTypes[selectedDatensatz]['bebaute-flaechen_' + props.last].label
							: '?';
						const colorLast = $classTypes[selectedDatensatz][props.last]
							? $classTypes[selectedDatensatz][props.last].color
							: $classTypes[selectedDatensatz]['bebaute-flaechen_' + props.last]
							? $classTypes[selectedDatensatz]['bebaute-flaechen_' + props.last].color
							: '#000';

						htmlContent = `<div class='p-2 text-sm space-y-1'>
								<h4>Veränderung: ${selectedSize} ha </h4>
								<div class='text-sm flex items-center'>
										<span class="circle" style="background-color:${colorFirst}; opacity:${$mapLayerOpacity}"></span>
										<span><b>${$selectedAdminDataYears[0] || '?'}</b>: ${labelFirst}</span> 
								</div>
								<div class='text-sm flex items-center'>
										<span class="circle" style="background-color:${colorLast}; opacity:${$mapLayerOpacity}"></span>
										<span><b>${$selectedAdminDataYears[1] || '?'}</b>: ${labelLast}</span>
					 			</div
								`;
					}

					htmlContent += `<div>`;

					popup = new mapboxgl.Popup()
						.setLngLat(e.lngLat)
						.setHTML(htmlContent)
						.setMaxWidth('300px')
						.addTo(map);
				} else {
					// nothing at this coordinate
				}
			})
			.catch(function (error) {
				console.warn('Something went wrong with the request: ', error);
			});
	}

	let map;
	let mapHasBasemap = true;
	let tilesLoading = false;
	let mapFullyLoaded = false;
	let previousSelectedAdminType = $selectedAdminType;
	let previousSelectedAdminId = $selectedAdminId;
	let previousSelectedBackgroundLayer = $mapSelectedBackgroundLayer;
	let popup;
	let mobileShadowVisible = true;
	let mapColorLegend = false;
	let indicatorUnit;

	function onAdminIdChange(selectedAdminId) {
		// a previousSelectedAdminId is used here because mapbox filtering has
		// a bug whereby the last filtered layer is shown for a split second
		if (!mapFullyLoaded) return;
		if (map.getLayer('selectedAdminLayer' + previousSelectedAdminId)) {
			map.removeLayer('selectedAdminLayer' + previousSelectedAdminId);
		}
		if (!selectedAdminId) return;

		map.addLayer({
			id: 'selectedAdminLayer' + selectedAdminId,
			type: 'line',
			source: 'admin-boundaries',
			'source-layer': $selectedAdminType,
			layout: {
				'line-join': 'round',
				'line-cap': 'round'
			},
			paint: {
				'line-color': '#037aff',
				'line-width': 3
			},
			filter: ['==', '$id', Number(selectedAdminId)]
		});

		previousSelectedAdminId = selectedAdminId;
	}

	function onAdminTypeChange(selectedAdminType) {
		if (!mapFullyLoaded) return;
		if ($selectedDataView === 'analyse') {
			map.setPaintProperty(selectedAdminType, 'fill-opacity', $mapLayerOpacity);
		} else {
			map.setPaintProperty(selectedAdminType, 'fill-opacity', 0);
			setLayerSource('selectedAdminLayerLine', 'admin-boundaries', selectedAdminType);
		}

		continueStyling();
		function continueStyling() {
			if (map.isStyleLoaded()) {
				setTimeout(() => {
					map.setLayoutProperty(previousSelectedAdminType, 'visibility', 'none');
					map.setLayoutProperty(selectedAdminType, 'visibility', 'visible');
					previousSelectedAdminType = selectedAdminType;
				}, 200);
			} else {
				setTimeout(() => {
					continueStyling();
				}, 100);
			}
		}
	}

	function onInfobarToggle(infoBarVisible) {
		if (!mapFullyLoaded) return;
		if (!$isMobile) {
			map.easeTo({
				padding: { right: !infoBarVisible ? 0 : 350 },
				duration: 500 // In ms, CSS transition duration property for the sidebar matches this value
			});
		}
	}

	function onIndicatorDataChange(indicatorData, selectedAdminType, normaliseColoring) {
		if (!mapFullyLoaded || !indicatorData) return;

		indicatorUnit = $indicatorTypesInfo[$selectedIndicator].unit;
		
		map.getSource('admin-boundaries').attribution = $veraenderungsLayers[selectedDatensatz].attribution;
		// hacky solution: https://gis.stackexchange.com/questions/407876/how-to-update-source-property-attribution-in-mapbox-gl
		for (let contr of map._controls) {
			if (contr._toggleAttribution) {
				contr._updateAttributions();
				break;
			}
		}
		
		const filterIndicatorData = {};
		for (const key in indicatorData) {
			if (
				$lookupTable[key] &&
				$lookupTable[key].art_gebietseinheit &&
				$lookupTable[key].art_gebietseinheit.toLowerCase() === selectedAdminType
			) {
				filterIndicatorData[key] = indicatorData[key];
			}
		}

		const filterIndicatorDataMain = {};
		if (normaliseColoring) {
			for (const key in indicatorDataFromMain) {
				if (
					$lookupTable[key] &&
					$lookupTable[key].art_gebietseinheit &&
					$lookupTable[key].art_gebietseinheit.toLowerCase() === selectedAdminType
				) {
					filterIndicatorDataMain[key] = indicatorDataFromMain[key];
				}
			}
		}

		const reds = ['#EF7778', '#C05E5F', '#934748', '#693131', '#411C1C'];
		const greens = ['#79c887', '#5ca76a', '#3b7849', '#1d4527', '#060f08'];

		const inputColors = $selectedIndicator.indexOf('siedlung_') !== -1 ? reds : greens;

		const color = scaleCluster()
			.domain([
				...Object.values(
					normaliseColoring
						? filterObject(filterIndicatorDataMain, 'number')
						: filterObject(filterIndicatorData, 'number')
				)
			])
			.range(inputColors);

		mapColorLegend = inputColors.map((c) => {
			return { value: color.invertExtent(c), color: c };
		});

		// for some reason the max value is not working so it is added/overwritten here
		// mapColorLegend[mapColorLegend.length - 1].value[1] = Math.max(
		// 	...Object.values(normaliseColoring ? filterIndicatorDataMain : filterIndicatorData)
		// );

		for (const key in filterIndicatorData) {
			filterIndicatorData[key];
			
			map.setFeatureState(
				{
					source: 'admin-boundaries',
					sourceLayer: selectedAdminType,
					id: key
				},
				{
					color:
						filterIndicatorData[key] !== 'nodata'
							? color(filterIndicatorData[key]) || 'rgba(0,0,0,0.1)'
							: 'rgba(0,0,0,0.1)' // transparent when no data
				}
			);
		}
	}


	function onBackgroundLayerChange(bLayer) {
		if (!mapFullyLoaded) return;
		const basemapSettings = $mapBackgroundLayers[bLayer];
		let sessionCookie;
		if (
			!basemapSettings.hasOwnProperty('label') && 
			basemapSettings.hasOwnProperty('0') && 
			basemapSettings[0].authentication && 
			!$basemapSessionCookie
		) {
			sessionCookie = authenticateBKGsessionToken(basemapSettings[0]);
			addBaseLayer(sessionCookie);
		} else if (
			basemapSettings.hasOwnProperty('label') && 
			basemapSettings.authentication && 
			!$basemapSessionCookie
		) {
			sessionCookie = authenticateBKGsessionToken(basemapSettings);
			addBaseLayer(sessionCookie);
		} else {
			addBaseLayer();
		}
		
		function authenticateBKGsessionToken(basemapSettingsItem) {
			const key = basemapSettingsItem.authentication.key;
			const domain = basemapSettingsItem.authentication.domain;

			//from franksands via https://stackoverflow.com/a/77289727/4649719
			const request = new XMLHttpRequest();
			request.open("GET", `https://sg.geodatenzentrum.de/gdz_getSession?bkg_appid=${key}&domain=${domain}`, false); // `false` makes the request synchronous
			request.send(null);

			if (request.status === 200) {
				return request.responseText;
			} else {
				console.log(request.status)
			}
			return null;
		}

		function addOneBaseLayer (basemapSettingsItem, sessionCookie) {
			// if base map requires authentification
			const tileSource = basemapSettingsItem.authentication
				? [basemapSettingsItem.source.tiles[0].replace('$SESSIONCOOKIE$', sessionCookie)]
				: basemapSettingsItem.source.tiles;

			map.addSource(basemapSettingsItem.label, {
				type: basemapSettingsItem.source.type,
				tiles: tileSource,
				tileSize: basemapSettingsItem.source.tileSize,
				attribution: basemapSettingsItem.source.attribution
			});

			map.addLayer(
				{
					id: basemapSettingsItem.label,
					type: basemapSettingsItem.source.type,
					source: basemapSettingsItem.label,
					paint: {
						// 'raster-opacity': $mapLayerOpacity
					}
				},
				'bundesland'
			);
		}

		function addBaseLayer(sessionCookie) {
			if (sessionCookie) {
				$basemapSessionCookie = sessionCookie;
			}
			
			const baselayerIds = [] 
			for (const id of Object.getOwnPropertyNames($mapBackgroundLayers)) {
				if (id!='none' && Array.isArray($mapBackgroundLayers[id])) {
					for (const subid of $mapBackgroundLayers[id].reverse()) {
						baselayerIds.push(subid.label)
					}
				} else if ((id!='none' && !Array.isArray($mapBackgroundLayers[id]))) {
					baselayerIds.push($mapBackgroundLayers[id].label)
				}
			}
			for (const baseLayer of baselayerIds) {
				if (map.getLayer(baseLayer)) {
					map.removeLayer(baseLayer);
					map.removeSource(baseLayer);
				}
			}

			if (bLayer === 'none') return;
			
			if (!basemapSettings.hasOwnProperty('label')) {
				for (const basemapSettingsItem of basemapSettings) {
					addOneBaseLayer(basemapSettingsItem, $basemapSessionCookie)
				}
			} else {
				addOneBaseLayer(basemapSettings, $basemapSessionCookie)
			}
		}
	}

	function onOpacityChange(opacity, selectedAdminType) {
		if (!mapFullyLoaded) return;
		if ($selectedDataView === 'analyse') {
			map.setPaintProperty(selectedAdminType, 'fill-opacity', opacity);
		} else {
			if (map.getLayer('rasterLayer')) {
				map.setPaintProperty('rasterLayer', 'raster-opacity', opacity);
			}
		}
	}

	function onDataViewChange(selectedDataView) {
		if (popup) {
			popup.remove();
		}
		if (!mapFullyLoaded) return;
		mapHasBasemap = true;
		if (map.getLayer('rasterLayer')) {
			map.removeLayer('rasterLayer');
			map.removeSource('rasterLayerSource');
		}
		if (map.getLayer('selectedAdminLayerLine')) {
			map.removeLayer('selectedAdminLayerLine');
		}
		if (selectedDataView === 'veraenderung' || selectedDataView === 'klassen') {
			// add (wms) layer
			const tileSource =
				selectedDataView === 'veraenderung'
					? $veraenderungsLayers[selectedDatensatz]
					: $klassenLayers[selectedDatensatz];
			
			if (!tileSource.type) {
				const sourceObject = map.getSource('admin-boundaries');
				sourceObject.attribution = tileSource.attribution
			}
			
			// set analyse layer to 0 opacity to hide but keep the click events
			map.setPaintProperty($selectedAdminType, 'fill-opacity', 0);
			// add a line layer
			map.addLayer(
				{
					id: 'selectedAdminLayerLine',
					type: 'line',
					source: 'admin-boundaries',
					'source-layer': $selectedAdminType,
					layout: {
						'line-join': 'round',
						'line-cap': 'round'
					},
					paint: {
						'line-color': '#07253a',
						'line-width': 0.5
						// ,
						// 'line-opacity': 0.4
					}
				},
				'gemeindeverband'
			);
			
			if (!tileSource.type) {
				mapHasBasemap = false;
				return;
			}

			map.addSource('rasterLayerSource', {
				type: 'raster',
				tiles: [tileSource.url],
				tileSize: 256,
				attribution: tileSource.attribution
			});
			map.addLayer(
				{
					id: 'rasterLayer',
					type: 'raster',
					source: 'rasterLayerSource',
					paint: {
						'raster-opacity': $mapLayerOpacity
					}
				},
				'gemeindeverband'
			);
		} else {
			// Analyse
			map.setPaintProperty($selectedAdminType, 'fill-opacity', $mapLayerOpacity);
		}
	}

	function onSelectedDatensatzChange(selectedDatensatz) {
		onDataViewChange($selectedDataView);
	}

	function onMapZoomChange(z) {
		if (!mapFullyLoaded || mapId === 0) return;
		if (map && map.getZoom() !== z) {
			map.setZoom(z);
		}
	}

	function onMapCenterChange(c) {
		if (!mapFullyLoaded || mapId === 0) return;

		if (map && map.getCenter().lng !== c.lng && map.getCenter().lat !== c.lat) {
			map.setCenter(c);
		}
	}

	let previousExpertModeVisible;
	function onExpertModeVisibilityChange() {
		// when closing
		if (mapId === 0 && previousExpertModeVisible && !$expertModeVisible) {
			map.setCenter($mapCenter);
			map.setZoom($mapZoom);
		}
		previousExpertModeVisible = $expertModeVisible;
	}

	function onIndicatorDataFromMainChange() {
		if (allowNormalising && normaliseColoring) {
			onIndicatorDataChange(indicatorData, $selectedAdminType, normaliseColoring);
		}
	}

	$: {
		onDataViewChange($selectedDataView);
	}

	$: {
		onAdminTypeChange($selectedAdminType);
	}

	$: {
		onAdminIdChange($selectedAdminId);
	}

	$: {
		onIndicatorDataChange(indicatorData, $selectedAdminType, normaliseColoring);
	}

	$: {
		onInfobarToggle($infoBarVisible);
	}

	$: {
		onBackgroundLayerChange($mapSelectedBackgroundLayer);
	}

	$: {
		onSelectedDatensatzChange(selectedDatensatz);
	}

	$: {
		onOpacityChange($mapLayerOpacity, $selectedAdminType);
	}

	$: {
		onMapZoomChange($mapZoom);
	}

	$: {
		onMapCenterChange($mapCenter);
	}

	$: {
		onExpertModeVisibilityChange($expertModeVisible);
	}

	$: {
		if (indicatorDataFromMain) {
			onIndicatorDataFromMainChange(indicatorDataFromMain);
		}
	}

	$: mapElementTranslateX =
		!$infoBarVisible && !$isMobile && !$expertModeVisible
			? `transform: translateX(350px) translateX(-${mapWidth / 5 / 2}px);`
			: 'transform: translateX(0px);';

	let stateMsgWidth;

	$: if ($expertModeVisible & !$isMobile) {
		stateMsgWidth = '30%';
	} else if (!$expertModeVisible & !$isMobile) {
		stateMsgWidth = '20%';
	} else if (!$expertModeVisible & $isMobile) {
		stateMsgWidth = '50%';
	}

	onMount(() => {
		map = new mapboxgl.Map({
			container: `map-${mapId}`, // container id
			style: mapStyle(window.location.origin), // map style
			center: validateQuery($query.mc, { type: 'mapCenter', fallback: $mapCenter }), // starting position [lng, lat]
			zoom: validateQuery($query.mz, { type: 'mapZoom', fallback: $mapZoom }), // starting zoom,
			maxPitch: 0,
			maxRotation: 0,
			// hash: true
			fadeDuration: 0,
			maxBounds: $isMobile
				? [
						[-0.003719407654045881, 43.42293021925772],
						[21.172991685257074, 57.24622720214256]
				  ]
				: [
						[-30.382700743862074, 35.50079158851791],
						[50.66001721360996, 61.739625507320056]
				  ],
			scrollZoom: scrollZoom,
			attributionControl: false,
		})
		.addControl(
			new mapboxgl.AttributionControl({
				compact: false, 
				customAttribution: '&copy; Bundesamt für Kartographie und Geodäsie 2022'})
		)
		
		if ($mapCenter === [10.371, 51.524] && $mapZoom === 5) {
			map.fitBounds([
				[-5.471285156247291, 45.353934189566985],
				[26.169339843752567, 55.987515547409146]
			]);
		}

		if (!$isMobile) {
			map.setPadding({ right: $isMobile ? 0 : 350, left: $isMobile ? 0 : 350 });
		}

		if (showZoomControls) {
			let pos;
			if (!$isMobile) {
				pos = 'top-left';
			} else {
				pos = 'top-right';
			}

			let control = map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), pos);
			let controlEl = (control['_controlPositions']['top-left'].style.left = zoomNavPosLeft);
		}

		map.on('load', function () {
			mapFullyLoaded = true;
			onDataViewChange($selectedDataView);
			onAdminTypeChange($selectedAdminType);
			onAdminIdChange($selectedAdminId);
			onIndicatorDataChange(indicatorData, $selectedAdminType);
			onBackgroundLayerChange($mapSelectedBackgroundLayer);
			onOpacityChange($mapLayerOpacity, $selectedAdminType);
		});

		map.on('click', (e) => {
			$mapLayerSwitcherVisible = false;
			$mapOpacitySliderVisible = false;
			$visibleSidebarEl = 1;
			$mobileSearchVisible = false;
			const features = map.queryRenderedFeatures(e.point);
			$selectedAdminId = features[0] ? features[0].id : null;
			if ($selectedAdminId) {
				// show infobar when click on admin
				$infoBarVisible = true;
			}
			if ($selectedDataView === 'veraenderung') {
				if ($mapZoom > 7) {
					getWMSInfo(e);
				}

				// getWMSFeatureInfo(e,function(data){})
			}
		});

		map.on('zoomend', function () {
			if (!mapFullyLoaded) return;
			$query.mz = Math.round(map.getZoom() * 1000) / 1000;
			$mapZoom = map.getZoom();
		});

		let moveEndTimer;
		map.on('moveend', function () {
			if (!mapFullyLoaded) return;
			clearTimeout(moveEndTimer);
			moveEndTimer = setTimeout(() => {
				$query.mc =
					Math.round($mapCenter.lng * 1000) / 1000 + '-' + Math.round($mapCenter.lat * 1000) / 1000;
			}, 200);
		});

		// let moveTimer;
		map.on('move', function () {
			if (!mapFullyLoaded) return;
			$mapCenter = map.getCenter();
		});

		let timeout;
		map.on('sourcedata', (e) => {
			// only show the loading msg after 500ms
			if (!timeout) {
				timeout = setTimeout(() => {
					tilesLoading = true;
				}, 500);
			}
			if (map.areTilesLoaded()) {
				clearTimeout(timeout);
				timeout = false;
				tilesLoading = false;
			}
		});
	});

	function hideMobileShadow() {
		mobileShadowVisible = false;
	}
</script>

<div id={`map-${mapId}`} class="relative" bind:clientWidth={mapWidth}>
	{#if $isMobile}
		{#if mobileShadowVisible}
			<div
				id="mob-shadow"
				class="absolute top-0 left-0 w-full h-full z-50 flex items-center justify-center"
				on:click={hideMobileShadow}
			>
				<div class="text-center" on:click={hideMobileShadow}>
					<div class="bg-white -mt-24 p-4 rounded">
						<div class="icon-tap text-3xl pb-2" />
						<div>Tippen, um Karte zu nutzen</div>
					</div>
				</div>
			</div>
		{/if}
	{/if}

	<!-- <MapLayerSwitcher /> -->
	{#if showLayerSwitch}
		<MapLayerSwitcher posLeft={layerSwitchPosLeft} posLeftReveal={layerSwitchPosContent} />
	{/if}

	{#if showOpacitySlider}
		<MapOpacitySlider posLeft={opSliderPosLeft} posLeftReveal={opSliderPosContent} />
	{/if}

	<!-- <MapResetZoom /> -->
	<div
		class="block lg:hidden icon-resize bg-white rounded cursor-pointer p-4 lg:p-1 absolute right-4 lg:left-100 top-50 lg:top-24 border z-10 font-sans text-base"
		on:click={() => {
			map.fitBounds([
				[5.603446543536933, 47.13359790765517],
				[15.215786118146042, 55.68297771629631]
			]);
		}}
	/>
	<!-- <MapScale /> -->
	<MapScale
		bind:mapColorLegend
		{mapScalePad}
		{mapScalePosBottom}
		{opacitySlider}
		bind:normaliseColoring
		{allowNormalising}
		unit={indicatorUnit}
		translateX={mapElementTranslateX}
	/>
	<div class="z-30" id="map_state_message">
		{#if tilesLoading}
			<div
				class="m-auto p-4 rounded opacity-reduced"
				style="width:{stateMsgWidth}; transition: 500ms transform; {mapElementTranslateX}"
			>
				<!-- shadow-lg -->
				<Spinner />
				<div class="text-sm text-select">Daten werden geladen</div>
			</div>
		{/if}
		{#if !mapHasBasemap}
			<!-- shadow-lg -->
			<div
				class="m-auto p-4 rounded opacity-reduced"
				style="width:{stateMsgWidth}; transition: 500ms transform; {mapElementTranslateX}"
			>
				<div class="text-sm text-select">Keine Hintergrundkarte verfügbar</div>
			</div>
		{/if}
	</div>
</div>

<slot />

<style lang="scss">
	@import '../../../styles/variables.scss';
	
	#map-0,
	#map-1,
	#map-2 {
		width: 100%;
		height: 100%;
		background-color: white;
		font-family: $font-default;
		font-size: $font-size-default;
	}

	#map_state_message {
		top: 35%;
		width: 100%;
		position: absolute;
		text-align: center;
		pointer-events: none;

		.opacity-reduced {
			background-color: rgba(255, 255, 255, 0.6);
		}
	}

	#mob-shadow {
		background-color: rgba(0, 0, 0, 0.5);
	}

	:global {
		.circle {
			display: inline-block;
			width: 14px;
			height: 14px;
			margin-right: 7px;
			border-radius: 50%;
		}
	}
</style>

<script>
	import { infoBarVisible } from '$lib/stores/storesDashboard';
	import { isMobile } from '$lib/stores/storesShared';
	import { selectedDataView } from '$lib/stores/storesDashboard';
	import { selectedAdminId } from '$lib/stores/storesDashboard';

	function toggleInfo() {
		$infoBarVisible = !$infoBarVisible;
	}

	$: zIndex = function () {
		if ($selectedDataView == 'analyse' && $selectedAdminId) {
			return 'z-0';
		} else if ($selectedDataView == 'analyse' && !$infoBarVisible) {
			return 'z-40';
		} else {
			return $isMobile ? 'z-0' : 'z-40';
		}
	};
</script>

{#if !$infoBarVisible}
	<button
		class="text-sm btn-show-data bg-select text-white rounded w-64 lg:w-40 lg:m-0 p-3 absolute bottom-24 lg:bottom-20 lg:right-5 cursor-pointer {zIndex()}"
		on:click={() => toggleInfo()}
	>
		<span class="icon-eye-on" />
		<span>Daten anzeigen</span>
	</button>
{/if}

<style lang="scss">
	@media screen and (max-width: 1024px) {
		.btn-show-data {
			right: calc(50vw - 8rem);
		}
	}
</style>

<script>
	export let chartUnit;
	export let chartUnitSymbol;
	export let units;
	export let width;

	function onSelect(unit) {
		chartUnit = unit.value;
		chartUnitSymbol = unit.symbol;
	}
</script>

{#if units.length !== 1}
	<div class="switch text-right pb-4">
		<div>
			{#each units as unit, i}
				<button
					class="border-2 border-select text-select cursor-pointer px-2"
					class:active={chartUnit === unit.value}
					class:rounded-l={i === 0}
					class:rounded-r={i === units.length - 1}
					class:disabled={unit.disabled}
					on:click={() => onSelect(unit)}
					>{unit.label}
				</button>
			{/each}
		</div>
	</div>
{/if}

<style lang="scss">
	@import '../../../styles/variables.scss';

	.switch {
		margin: 0 auto;
		margin-top: 1rem;

		& button:first-of-type {
			margin-right: -2px;
		}

		& button:last-of-type {
			margin-left: -2px;
		}
	}

	.active {
		background-color: $color-select;
		border: 2px solid $color-select;
		color: white;
	}

	.disabled {
		pointer-events: none;
		background-color: rgb(234 234 234);
		color: #00000040;
		border-color: rgb(194, 194, 194);

		& button:first-of-type {
			margin-right: -2px;
		}

		& button:last-of-type {
			margin-left: -2px;
		}
	}
</style>

<script>
	import { mapLayerOpacity } from '$lib/stores/storesDashboard';
	import { formatPercent } from '$lib/utils/formatNumbers';
</script>

<div>
	<div class="mb-1">
		<input
			class="slider w-full cursor-pointer"
			type="range"
			bind:value={$mapLayerOpacity}
			min="0"
			max="1"
			step="0.01"
		/>
	</div>

	<div class="flex justify-between text-sm">
		<div class="w-16 text-left">0%</div>
		<div class="">Datensichtbarkeit</div>
		<div class="w-16 text-right">{formatPercent($mapLayerOpacity)}</div>
	</div>
</div>

<style lang="scss">
	@import '../../../styles/variables.scss';

	.slider {
		-webkit-appearance: none;
		width: 100%;
		height: 8px;
		background: #e5e7eb;
		outline: none;
		-webkit-transition: 0.2s;
		transition: opacity 0.2s;
		border-radius: 5px;
	}

	.slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 20px;
		height: 20px;
		background: $color-select;
		cursor: pointer;
		border-radius: 50%;
		border: 2px solid white;
	}

	.slider::-moz-range-thumb {
		width: 20px;
		height: 20px;
		background: $color-select;
		cursor: pointer;
		border-radius: 50%;
		border: 2px solid white;
	}
</style>

<script>
	import { onDestroy } from 'svelte';
	import {
		adminTypes,
		dataViewTypes,
		indicatorTypesInfo,
		selectedAdminType,
		selectedAdminId,
		selectedIndicator,
		selectedDataView,
		expertModeVisible,
		selectedIndicatorGroup,
		selectedDatensatz,
		indicatorListSiedlung,
		indicatorListVegetation,
		indicatorData,
		selectedAdminDataYears,
		selectedAdminData,
		selectedAdminName,
		selectedAdminNameArt
	} from '$lib/stores/storesDashboard';
	import {
		selectedDatensatzCompared,
		indicatorDataCompared,
		selectedAdminDataCompared,
		selectedAdminDataYearsCompared
	} from '$lib/stores/storesExpertMode';
	import query from 'query-store';
	import { datensaetze } from '$lib/stores/storesShared';
	import Dropdown from './Dropdown.svelte';
	import SearchAdmin from '$lib/main/dashboard/search/SearchAdmin.svelte';
	import Map from '$lib/main/dashboard/map/Map.svelte';
	import DatasetInfo from './DatasetInfo.svelte';
	import { fly } from 'svelte/transition';
	import { loadIndicatorData } from '$lib/utils/loadIndicatorData';
	import { loadAdminData } from '$lib/utils/loadAdminData';
	// import { prepairChartData } from '$lib/utils/prepairChartData';

	import iconSliders from './icons/Sliders.svelte';
	import iconGermany from './icons/Germany.svelte';
	import iconDatabase from './icons/Database.svelte';
	import iconBar from './icons/Bar.svelte';
	import iconBarInactive from './icons/BarInactive.svelte';
	import UnitSwitcher from '$lib/main/dashboard/infobar/UnitSwitcher.svelte';

	import ExpertChart from './ExpertChart.svelte';
	import ExpertDisclaimer from './ExpertDisclaimer.svelte';

	import { getOptionsAdmins, getOptionsIndicators, getOptionsViews } from './expertDropdownOptions';

	let chartUnit = 'ha';
	let chartUnitSymbol = 'ha';
	let units = [
		{ value: 'ha', label: 'ha', symbol: 'ha' },
		{ value: 'prozent', label: '%', symbol: '%' }
	];

	function setUnits(selectedIndicator, selectedDataView) {
		if (selectedDataView === 'analyse') {
			units = [];
			const indicatorName = selectedIndicator.split('_')[0] + '_' + selectedIndicator.split('_')[1];
			for (const key in $indicatorTypesInfo) {
				if (key.indexOf(indicatorName) !== -1) {
					units.push({
						value: key,
						label: $indicatorTypesInfo[key].unit,
						symbol: $indicatorTypesInfo[key].symbol
					});
				}
			}
			chartUnit = selectedIndicator;
			chartUnitSymbol = units[0].symbol;
		} else {
			chartUnit = 'ha';
			chartUnitSymbol = 'ha';
			units = [
				{ value: 'ha', label: 'ha', symbol: 'ha' },
				{ value: 'prozent', label: '%', symbol: '%' }
			];
		}
	}

	$: {
		setUnits($selectedIndicator, $selectedDataView);
	}
	const optionsIndicators = getOptionsIndicators($indicatorListSiedlung, $indicatorListVegetation);
	const startValueIndicator = $indicatorTypesInfo[$selectedIndicator].label;

	const optionsAdmins = getOptionsAdmins($adminTypes);
	const startValueAdmin = $adminTypes[$selectedAdminType].label;

	const optionsViews = getOptionsViews($dataViewTypes);
	const startValueDataView = $dataViewTypes[$selectedDataView].label;

	const optionsDatensaetze = [];
	let startLabelDatensaetze;
	let startLabelDatensaetzeCompared;
	for (const key in $datensaetze) {
		const group = $datensaetze[key].label;
		for (const keyTwo in $datensaetze[key].subDatensaetze) {
			optionsDatensaetze.push({
				value: keyTwo,
				label: $datensaetze[key].subDatensaetze[keyTwo].label,
				group: group
			});
			if ($selectedDatensatz === keyTwo) {
				startLabelDatensaetze = $datensaetze[key].subDatensaetze[keyTwo].label;
			}
			if ($selectedDatensatzCompared === keyTwo) {
				startLabelDatensaetzeCompared = $datensaetze[key].subDatensaetze[keyTwo].label;
			}
		}
	}

	function adminChange() {
		$selectedAdminId = null;
	}

	function datensatzChange(v) {
		$query.mdatensatz = $selectedDatensatz;
	}

	$: {
		loadAdminData($selectedAdminId, $selectedDatensatzCompared, selectedAdminDataCompared, {
			allowLoading: true
		});
	}

	$: {
		loadIndicatorData($selectedIndicator, $selectedDatensatzCompared, indicatorDataCompared, {
			allowLoading: true
		});
	}

	onDestroy(() => {
		$selectedIndicatorGroup =
			$selectedIndicator.indexOf('siedlung') !== -1 ? 'Siedlung' : 'Vegetation';
	});
</script>

<div class="wrapper absolute z-50 h-full w-full" transition:fly|local={{ y: 300, duration: 200 }}>
	<div class="content bg-white h-fit m-5 rounded overflow-y-auto">
		<header class="relative bg-white w-full h-20 rounded-t">
			<div class="wrapper flex h-full items-center justify-around">
				<SearchAdmin />
				<Dropdown
					placeholder="Verwaltungseinheit wählen"
					width="14"
					items={optionsAdmins}
					icon={iconGermany}
					bind:value={$selectedAdminType}
					startLabel={startValueAdmin}
					noOptionsMessage="Kein Ergebnis"
					on:valueChange={adminChange}
				/>
				<Dropdown
					placeholder="Datenansicht wählen"
					width="12"
					items={optionsViews}
					icon={iconSliders}
					bind:value={$selectedDataView}
					startLabel={startValueDataView}
					noOptionsMessage="Kein Ergebnis"
				/>
				<Dropdown
					width="22"
					items={optionsIndicators}
					icon={$selectedDataView !== 'analyse' ? iconBarInactive : iconBar}
					bind:value={$selectedIndicator}
					startLabel={startValueIndicator}
					isDisabled={$selectedDataView !== 'analyse'}
					group={true}
					noOptionsMessage="Kein Indikator gefunden"
				/>
				<div on:click={() => ($expertModeVisible = false)} class="cursor-pointer icon-close" />
			</div>
		</header>
		<div class="map-container flex relative">
			<div class="relative flex-1 border-r">
				<DatasetInfo dataset={$selectedDatensatz} years={$selectedAdminDataYears} />
				<Map
					mapId={1}
					showLayerSwitch={true}
					layerSwitchPosLeft="1rem"
					layerSwitchPosContent="5rem"
					showZoomControls={true}
					showOpacitySlider={true}
					opSliderPosLeft="1rem"
					opSliderPosContent="5rem"
					zoomNavPosLeft="1rem"
					mapScalePad="2"
					mapScalePosBottom="1"
					scrollZoom={false}
					bind:selectedDatensatz={$selectedDatensatz}
					bind:indicatorData={$indicatorData}
				>
					<span class="datensatz-select">
						<Dropdown
							width="19"
							items={optionsDatensaetze}
							icon={iconDatabase}
							bind:value={$selectedDatensatz}
							startLabel={startLabelDatensaetze}
							noOptionsMessage="Kein Datensatz gefunden"
							on:valueChange={datensatzChange}
							group={true}
						/>
					</span>
				</Map>
			</div>
			<div class="relative flex-1">
				<DatasetInfo dataset={$selectedDatensatzCompared} years={$selectedAdminDataYearsCompared} />
				<Map
					mapId={2}
					showLayerSwitch={false}
					showZoomControls={false}
					showOpacitySlider={false}
					mapScalePad="2"
					mapScalePosBottom="1"
					scrollZoom={false}
					bind:selectedDatensatz={$selectedDatensatzCompared}
					bind:indicatorData={$indicatorDataCompared}
					bind:indicatorDataFromMain={$indicatorData}
					allowNormalising={true}
				>
					<span class="datensatz-select">
						<Dropdown
							width="19"
							items={optionsDatensaetze}
							icon={iconDatabase}
							bind:value={$selectedDatensatzCompared}
							startLabel={startLabelDatensaetzeCompared}
							noOptionsMessage="Kein Datensatz gefunden"
							group={true}
						/>
					</span>
				</Map>
			</div>
			<ExpertDisclaimer />
		</div>
		<div class="charts">
			<div class="chart-info text-sm flex">
				<div class="flex-1 border-r flex items-center pt-2">
					<div class="font-bold text-select flex-1 ml-8">
						{$selectedAdminName}
						{$selectedAdminNameArt}
					</div>

					<div class="flex-1 mr-8">
						<UnitSwitcher
							bind:chartUnit
							bind:chartUnitSymbol
							{units}
							width={$selectedDataView === 'analyse' ? '60' : '20'}
						/>
					</div>
				</div>
				<div class="flex-1" />
			</div>
			<div class="chart-container flex">
				<div class="chart rounded-br border-r py-4 flex-1">
					<div class="chart-info text-sm flex justify-around items-center" />

					<div class="flex justify-center ml-12">
						<ExpertChart
							selectedAdminData={$selectedAdminData}
							selectedAdminDataYears={$selectedAdminDataYears}
							selectedDatensatz={$selectedDatensatz}
							{chartUnit}
							{chartUnitSymbol}
						/>
					</div>
				</div>

				<div class="chart rounded-br py-4 flex-1">
					<div class="chart-info text-sm flex justify-around items-center" />

					<div class="flex justify-center">
						<ExpertChart
							selectedAdminData={$selectedAdminDataCompared}
							selectedAdminDataYears={$selectedAdminDataYearsCompared}
							selectedDatensatz={$selectedDatensatzCompared}
							{chartUnit}
							{chartUnitSymbol}
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<style lang="scss">
	header {
		position: sticky;
		top: 0px;
		z-index: 1000;
	}
	.wrapper {
		background-color: #ffffffe6;
	}
	.content {
		height: 95%;
		box-shadow: 0 -10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}

	.map-container {
		height: 65%;
	}

	.datensatz-select {
		position: absolute;
		top: 1rem;
		left: calc(50% - 9rem);
	}

	@import '../../../styles/variables.scss';
</style>

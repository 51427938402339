<script>
	import { scaleLinear } from 'd3-scale';
	import { formatInt, formatThreeDecimals, formatOneDecimal } from '$lib/utils/formatNumbers';

	// source https://awik.io/determine-color-bright-dark-using-javascript/
	function lightOrDark(color) {
		// Variables for red, green, blue values
		var r, g, b, hsp;

		// Check the format of the color, HEX or RGB?
		if (color.match(/^rgb/)) {
			// If RGB --> store the red, green, blue values in separate variables
			color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

			r = color[1];
			g = color[2];
			b = color[3];
		} else {
			// If hex --> Convert it to RGB: http://gist.github.com/983661
			color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

			r = color >> 16;
			g = (color >> 8) & 255;
			b = color & 255;
		}

		// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
		hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

		// Using the HSP value, determine whether the color is light or dark
		if (hsp > 127.5) {
			return 'light';
		} else {
			return 'dark';
		}
	}

	const isChildOverlapping = function (parent, child) {
		var box1coords = parent.getBoundingClientRect();
		var box2coords = child.getBoundingClientRect();

		if (
			// box2coords.top < box1coords.top ||
			box2coords.right > box1coords.right ||
			// box2coords.bottom > box1coords.bottom ||
			box2coords.left < box1coords.left
		) {
			return true;
		}

		return false;
	};

	const divsOverlapping = function (rect1, rect2) {
		var overlap = !(
			rect1.getBoundingClientRect().right < rect2.getBoundingClientRect().left ||
			rect1.getBoundingClientRect().left > rect2.getBoundingClientRect().right
		);
		return overlap;
	};

	const valToString = function (val) {
		if (val == undefined) {
			return '';
		}

		if (val === 'nodata') {
			return 'keine Daten';
		}

		if (val === 0) {
			return val + ' ' + chartUnitSymbol;
		}

		let valString = '';
		if (chartUnitSymbol === '%') {
			valString = val.toLocaleString('de-DE') + ' %';
		} else {
			valString = val.toLocaleString('de-DE') + ' ' + chartUnitSymbol;
		}

		if (chartUnitSymbol === '%' && valString == '0,000 %' && val !== 0) {
			valString = `< ${val < 0 ? '-' : ''}0,001`;
		}

		return valString;
	};

	export let chartData;
	export let chartUnitSymbol;
	export let chartLabelWidth;
	export let compactMode = false;

	let chartValues;
	let scale;
	let positions;
	let zeroPosition;

	function onUpdate(data) {
		let dataCopy = JSON.parse(JSON.stringify(data));
		data = data.map((d) => (d === null ? 0 : d)); // replace null values by 0
		chartValues = data;

		dataCopy = dataCopy.filter((d) => Number(d));
		const minValue = Math.min(...dataCopy) < 0 ? Math.min(...dataCopy) : 0;
		const maxValue = Math.max(...dataCopy) <= 0 ? 0 : Math.max(...dataCopy);

		scale = scaleLinear().domain([minValue, maxValue]).range([0, 100]);
		zeroPosition = scale(0);
		positions = [];
		data.forEach((v) => {
			const pos = {};
			if (v === 'nodata') {
				pos.left = scale(0);
				pos.width = scale(0) - pos.left;
			} else if (v <= 0) {
				pos.left = scale(v);
				pos.width = scale(0) - pos.left;
			} else {
				pos.left = scale(0); // if positive start a zero
				pos.width = scale(v) - pos.left;
			}

			positions.push(pos);
		});
	}

	$: {
		onUpdate(chartData.values);
	}

	function labelPosition(node, color, val) {
		node.style.right = 'unset';
		node.style.left = 'unset';
		node.style.opacity = 0;
		setTimeout(() => {
			const labelParentWidth = node.parentNode.getBoundingClientRect().width;
			const labelWidth = node.getBoundingClientRect().width;
			node.style.color = lightOrDark(color) === 'light' ? '#07253a' : '#fff';
			node.style.right = val <= 0 ? '0px' : 'unset';
			node.style.left = 'unset';

			// check if the label is outside the bar
			if (labelParentWidth <= labelWidth) {
				// its outside so we place it at the end of the bar
				node.style.color = '#07253a';
				if (val >= 0) {
					node.style.left = 100 + '%';
				} else {
					node.style.right = 100 + '%'; // width +
				}

				// in case the label is now over the bar container
				// move it to the other side of the zero axis
				if (isChildOverlapping(node.parentNode.parentNode, node)) {
					// its touching the label on the left
					if (divsOverlapping(node.parentNode.parentNode.parentNode.childNodes[0], node)) {
						node.style.left = 100 + '%';
						node.style.right = 'unset';
					} else {
						node.style.right = 100 + '%';
						node.style.left = 'unset';
					}
				}
			}

			// if (val !== 0) {
			node.style.opacity = 1;
			// }
		}, 500);
	}
	function handleLabelPosition(node, { color, val }) {
		labelPosition(node, color, val);
		return {
			update({ color, val }) {
				labelPosition(node, color, val);
			}
		};
	}
</script>

<div id="chart-container" class:compact={compactMode}>
	<div class="zero-line-wrapper">
		<div class="placeholder-label" style="width:{chartLabelWidth}px" />
		<div class="placeholder-bar" style="width:calc(100% - {chartLabelWidth}px)">
			<div class="zero-line" style="left:{zeroPosition}%" />
		</div>
	</div>

	{#each chartValues as val, i}
		<div class="row" style="height:35px">
			<div class="label" style="width:{chartLabelWidth}px">
				{chartData.labels[i]}
			</div>
			<div class="bar-wrapper" style="width:calc(100% - {chartLabelWidth}px)">
				<div
					class="bar"
					style="background-color:{chartData.backgroundColor[i]}; left:{positions[i]
						.left}%; width:{positions[i].width}%"
					class:no-data={val === 'nodata'}
				>
					<div
						class="bar-value"
						use:handleLabelPosition={{
							color: chartData.backgroundColor[i],
							val: val,
							width: positions[i].width
						}}
					>
						<!-- {#if val != 0} -->
						{valToString(val)}
						<!-- {/if} -->
					</div>
				</div>
			</div>
		</div>
	{/each}
</div>

<style lang="scss">
	.compact {
		.row {
			height: 20px !important;
			.label {
				line-height: 1 !important;
			}
		}
	}
	#chart-container {
		position: relative;
		font-size: 13px;
		// margin: 55px 10px 10px 10px;

		.row {
			align-items: center;
			display: flex;
			margin-bottom: 4px;
			margin-top: 4px;
			// height: 35px;

			.label {
				display: inline-block;
				margin-right: 5px;

				// &.no-data {
				// 	text-decoration: line-through;
				// }
			}

			.bar-wrapper {
				display: inline-block;
				height: 20px;
				position: relative;
				.bar-value {
					color: red;
					padding: 0px 5px;
					position: absolute;
					white-space: nowrap;
				}
				.bar {
					height: 100%;
					position: relative;
					transition: width 0.4s;
					&.no-data {
						background-color: rgba(0, 0, 0, 0) !important;
						.bar-value {
							color: #07253a !important;
						}
					}
				}
			}
		}
		.zero-line-wrapper {
			height: 100%;
			position: absolute;
			width: 100%;
			display: flex;

			.placeholder-label {
				display: inline-block;
				margin-right: 5px;
				height: 100%;
			}

			.placeholder-bar {
				height: 100%;
				display: inline-block;
				position: relative;

				.zero-line {
					border-left: 1px solid #c7c7c7;
					position: absolute;
					height: 100%;
					z-index: 1;
				}
			}
		}
	}
</style>

export default function (location) {
	const adminPaintStyle = {
		'fill-color': ['feature-state', 'color']
	};

	return {
		version: 8,
		name: 'orthoPhotos',
		metadata: {},
		sources: {
			'admin-boundaries': {
				type: 'vector',
				tiles: [location + '/tiles/{z}/{x}/{y}.pbf'],
				minzoom: 1,
				maxzoom: 13,
				bounds: [5.625, 47.309, 15.6, 55.354]
			}
		},
		// "glyphs": "./data/{fontstack}/{range}.pbf",
		layers: [
			{
				id: 'background',
				type: 'background',
				paint: {
					'background-color': '#f1f5f9'
				}
			},
			{
				id: 'bundesland',
				type: 'fill',
				source: 'admin-boundaries',
				'source-layer': 'bundesland',
				minzoom: 0,
				maxzoom: 22,
				paint: adminPaintStyle,
				layout: {
					visibility: 'none'
				}
			},
			{
				id: 'raumordnungsregion',
				type: 'fill',
				source: 'admin-boundaries',
				'source-layer': 'raumordnungsregion',
				minzoom: 0,
				maxzoom: 22,
				paint: adminPaintStyle,
				layout: {
					visibility: 'none'
				}
			},
			{
				id: 'kreis',
				type: 'fill',
				source: 'admin-boundaries',
				'source-layer': 'kreis',
				minzoom: 0,
				maxzoom: 22,
				paint: adminPaintStyle,
				layout: {
					visibility: 'none'
				}
			},
			{
				id: 'gemeindeverband',
				type: 'fill',
				source: 'admin-boundaries',
				'source-layer': 'gemeindeverband',
				minzoom: 0,
				maxzoom: 22,
				paint: adminPaintStyle,
				layout: {
					visibility: 'none'
				}
			}
		]
	};
}

export function getOptionsAdmins(adminTypes) {
	const adminsOptions = [];
	for (const key in adminTypes) {
		adminsOptions.push({
			value: key,
			label: adminTypes[key].label
		});
	}
	return adminsOptions;
}

export function getOptionsViews(dataViewTypes) {
	const dataViewOptions = [];
	for (const key in dataViewTypes) {
		dataViewOptions.push({
			value: key,
			label: dataViewTypes[key].label
		});
	}
	return dataViewOptions;
}

export function getOptionsIndicators(indicatorListSiedlung, indicatorListVegetation) {
	const indicatorsOptions = [];
	indicatorListSiedlung.forEach((indicator) => {
		indicatorsOptions.push({
			value: indicator.value,
			label: indicator.label,
			group: indicator.value.includes('siedlung') ? 'Siedlung' : 'Vegetation'
		});
	});
	indicatorListVegetation.forEach((indicator) => {
		indicatorsOptions.push({
			value: indicator.value,
			label: indicator.label,
			group: indicator.value.includes('siedlung') ? 'Siedlung' : 'Vegetation'
		});
	});

	return indicatorsOptions;
}

<svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M6.25227 3.60039L5.6325 3.5874L5.4707 3.33953L5.92915 3.08203L6.25227 3.60039Z"
		fill="#307CF6"
	/>
	<path
		d="M4.51493 2.66016L4.91688 2.72098L5.22908 3.21554L5.47092 3.34008L5.63271 3.58795L6.25249 3.60094L6.64135 3.75144L7.16953 3.81155L7.54962 4.26042L7.99498 4.43858L7.58826 4.77974L7.19124 4.70208L6.85533 4.97064L7.19955 5.57819L7.2893 6.16507L7.13874 6.50527L6.71201 6.53749L6.61949 7.08565L6.78313 7.38258L5.7394 7.94373L5.43551 7.96561L5.07806 7.36334L5.08483 6.95053L4.74154 6.70121L4.45843 6.07563L4.65179 5.73543L4.08959 5.79121L3.7546 5.58228L3.80048 6.27326L3.1767 6.48147L3.05155 5.85829L2.74766 5.78135L1.86864 6.11698L1.88019 5.88281L1.37402 5.59959L1.41097 5.28992L1.85463 5.26756L2.06923 4.48955L2.11788 3.8267L1.78059 3.75553L1.84955 3.38359L2.17853 3.07946L3.15007 3.01238L3.88022 3.42807L3.8345 3.2379L3.89746 2.73156L4.51493 2.66016Z"
		fill="#307CF6"
	/>
	<path
		d="M5.078 7.3631L4.68821 7.80307L4.28796 7.93915L4.15788 8.36158L3.30426 9.39901L2.79086 9.05977L2.26791 9.47523L1.51313 9.80966L0.976943 10.1643L0.93307 9.88395L0.428289 9.18864L0.528352 8.22502L0.719242 7.65617L0.358398 6.99621L0.968323 6.98563L1.34102 6.80411L1.36026 6.54157L1.86858 6.11674L2.7476 5.7811L3.05149 5.85804L3.17664 6.48122L3.80042 6.27301L3.75455 5.58203L4.08953 5.79096L4.65173 5.73518L4.45837 6.07538L4.74148 6.70097L5.08477 6.95029L5.078 7.3631Z"
		fill="#307CF6"
	/>
	<path
		d="M5.73959 7.93976L6.06503 8.40931L6.03609 8.74494L5.67324 9.43208L5.89215 9.75232L5.17831 10.6438L4.5102 10.608L4.62412 11.7344L4.54145 11.8967L3.91952 11.8565L3.70569 11.7221L3.57807 10.9112L3.37256 10.77L2.98277 10.9025L2.97754 10.4609L3.2982 10.2572L3.11085 9.7653L3.30452 9.39529L4.15813 8.35786L4.28821 7.93543L4.68847 7.79935L5.07825 7.35938L5.43571 7.96164L5.73959 7.93976Z"
		fill="#307CF6"
	/>
	<path
		d="M3.30433 9.39783L3.11067 9.76785L3.29802 10.2598L2.97735 10.4634L2.98259 10.9051L3.37237 10.7726L3.57789 10.9137L3.70551 11.7247L3.76462 12.3596L3.44796 13.0268L3.06033 12.8393L2.69533 12.8602L2.27368 12.6229L2.31617 12.2918L2.15191 11.7682L1.70593 11.6133L1.441 11.7788L0.945145 11.7932L1.10648 11.4549L0.747944 11.0572L0.589844 10.7072L0.658041 10.3735L0.977012 10.1631L1.5132 9.80848L2.26798 9.47405L2.79093 9.05859L3.30433 9.39783Z"
		fill="#307CF6"
	/>
	<path
		d="M5.29821 15.944L5.15304 15.806L4.34052 15.8084L3.96259 15.486L3.2506 15.9986L2.72304 16.049L2.48535 15.7778L2.62236 15.1229L2.56556 14.8039L2.78985 14.3627L2.92255 13.7044L3.4475 13.0271L3.76416 12.36L3.70505 11.725L3.91887 11.8594L4.5408 11.8996L4.62347 11.7373L4.90873 11.3398L5.55283 11.4144L5.73648 11.7974L5.99403 11.894L6.00373 12.5319L6.44555 13.0856L6.25958 13.9554L5.79698 14.2646L6.02651 14.9618L6.01189 15.748L5.29821 15.944Z"
		fill="#307CF6"
	/>
	<path
		d="M5.29842 15.9441L6.0121 15.7482L6.02672 14.962L5.79719 14.2648L6.25979 13.9556L6.44576 13.0857L6.00394 12.532L5.99424 11.8942L5.7367 11.7975L5.55304 11.4145L4.90894 11.34L4.62368 11.7374L4.50977 10.611L5.17788 10.6469L5.89172 9.75536L6.11139 9.67578L6.52904 10.1059L6.89804 10.0328L7.57032 10.0843L8.43286 9.95035L8.67424 10.1744L8.91686 10.7293L9.2794 11.0592L9.24291 11.5073L9.42456 12.0389L9.82235 12.2332L10.2095 12.7155L10.8638 13.211L11.0179 13.4581L10.8716 13.9844L10.5174 13.8991L10.4398 14.2792L9.72583 14.6372L9.5508 14.8367L9.89363 15.3784L10.0202 15.8487L9.89856 16.1966L9.44812 15.7383L9.11806 15.7073L8.82064 15.8804L8.07617 15.9439L7.55169 16.3379L7.18222 16.3435L7.03213 16.0429L6.45007 16.0038L6.30352 16.5002L5.78272 16.0487L5.29842 15.9441Z"
		fill="#307CF6"
	/>
	<path
		d="M2.27384 12.6228L1.42962 12.6086L0.945312 11.7931L1.44116 11.7787L1.7061 11.6133L2.15208 11.7681L2.31633 12.2918L2.27384 12.6228Z"
		fill="#307CF6"
	/>
	<path
		d="M10.7168 5.47864L10.8331 5.80009L10.0566 5.7871L10.0907 5.37718L10.4729 5.28125L10.7168 5.47864Z"
		fill="#307CF6"
	/>
	<path
		d="M11.7982 3.81773L11.6987 4.41013L11.4529 4.77654L12.1016 5.4103L11.9783 5.71564L12.2677 6.485L12.0391 6.99951L12.2257 7.50392L11.4263 7.58398L11.2408 7.92827L10.6222 7.95159L9.95841 7.36592L10.0551 6.92185L9.45717 6.61146L9.04706 6.53741L9.02551 6.52371L8.83154 6.26237L8.96655 5.66371L8.80675 4.77871L8.5158 4.76981L7.99516 4.43418L7.5498 4.25602L7.91003 4.24689L8.55706 3.80402L8.84893 3.75594L9.85834 4.16779L10.5459 3.89563L11.0066 3.46094L11.567 3.62202L11.7982 3.81773ZM10.7167 5.47522L10.4729 5.27783L10.0906 5.37376L10.0566 5.78368L10.8331 5.79666L10.7167 5.47522Z"
		fill="#307CF6"
	/>
	<path
		d="M11.7973 3.8206L11.566 3.62489L11.0057 3.4638L10.5449 3.89849L9.85743 4.17065L8.84802 3.75881L8.55614 3.80689L7.90912 4.24975L7.54889 4.25889L7.1688 3.81002L6.64062 3.74991L7.06489 3.30993L6.84599 2.71729L7.05596 2.49465L7.42666 2.37132L7.78874 2.5901L8.10955 2.07848L8.68207 2.02679L9.30585 1.58056L10.0631 1.83397L10.8938 2.34391L11.2046 2.8851L11.5991 2.97093L11.7973 3.8206ZM10.4067 1.04297L10.726 1.55868L10.3979 1.81786L10.0956 1.80247L10.092 1.26031L10.4067 1.04297Z"
		fill="#307CF6"
	/>
	<path
		d="M9.95774 7.36846L10.6215 7.95414L11.2401 7.93082L11.4256 7.58653L12.225 7.50647L12.5206 7.73848L12.6427 8.1621L12.3197 9.10938L11.7826 8.89564L11.0983 9.27768L10.6997 9.3462L10.5164 9.5722L9.23205 10.0119L8.97604 10.406L8.674 10.1735L8.43262 9.94942L8.91523 9.13703L9.37306 8.76966L8.91923 8.54582L8.79438 8.19144L8.79854 7.61225L9.67494 7.29297L9.95774 7.36846Z"
		fill="#307CF6"
	/>
	<path
		d="M9.95689 7.36924L9.6741 7.29374L8.7977 7.61303L8.79354 8.19221L8.91839 8.5466L8.58218 8.79399L7.7107 8.29319L7.72179 7.9453L7.15727 7.83254L6.9964 7.40194L6.78181 7.3815L6.61816 7.08458L6.71068 6.53641L7.13742 6.50419L7.28797 6.16399L7.19822 5.57711L6.85401 4.96956L7.18991 4.70101L7.58693 4.77866L7.99365 4.4375L8.51429 4.77313L8.80524 4.78203L8.96503 5.66703L8.83002 6.26569L9.02399 6.52703L9.04555 6.54073L9.45565 6.61479L10.0536 6.92517L9.95689 7.36924Z"
		fill="#307CF6"
	/>
	<path
		d="M8.91952 8.54791L9.37334 8.77175L8.91552 9.13912L8.4329 9.95151L7.57036 10.0854L6.89809 10.034L6.52908 10.1071L6.11144 9.67695L5.89176 9.75653L5.67285 9.43628L6.0357 8.74915L6.06464 8.41351L5.7392 7.94396L6.78294 7.38281L6.99753 7.40325L7.15841 7.83385L7.72292 7.94661L7.71183 8.2945L8.58331 8.79531L8.91952 8.54791Z"
		fill="#307CF6"
	/>
	<path
		d="M7.05668 2.49456L6.8467 2.7172L7.06561 3.30984L6.64134 3.74982L6.25248 3.59931L5.92935 3.08096L5.47091 3.33845L5.22906 3.21391L4.91687 2.71936L4.51492 2.65853L4.35805 2.54217L4.27261 1.84085L4.34112 1.13809L4.05078 0.747637L4.41224 0.5L5.07835 0.707967L5.28649 0.628627L5.88194 0.945747L5.87101 1.24508L7.31361 1.98703L6.85255 2.32554L7.05668 2.49456Z"
		fill="#307CF6"
	/>
</svg>

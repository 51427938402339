<script>
	import InfobarIndikator from '$lib/main/dashboard/infobar/indikatoren/InfobarIndikator.svelte';
	import InfobarVeraenderung from '$lib/main/dashboard/infobar/veraenderung/InfobarVeraenderung.svelte';
	import InfobarKlassen from '$lib/main/dashboard/infobar/klassen/InfobarKlassen.svelte';
	import {
		infoBarVisible,
		selectedDataView,
		selectedDatensatz,
		datensatzLabelWidth,
		selectedAdminData,
		classTypes,
		selectedAdminDataYears,
		datasetUnits
	} from '$lib/stores/storesDashboard';
	import { datensaetze } from '$lib/stores/storesShared';

	import { isMobile } from '$lib/stores/storesShared';
	import slideFlexibleAxis from '$lib/utils/slideFlexibleAxis';
	import { prepairVerteilung, prepairVeraenderung } from '$lib/utils/prepairChartData';
	import { getDatensatzHeadings } from '$lib/utils/getDatensatzHeadings';

	function toggleInfo() {
		$infoBarVisible = !$infoBarVisible;
	}

	$: $isMobile ? ($infoBarVisible = false) : ($infoBarVisible = true);

	$: transitionDirection = $isMobile ? { axis: 'y' } : { axis: 'x' };

	let chartUnit = 'ha';
	let chartUnitSymbol = 'ha';
	let chartLabelWidth = 120;
	let chartDataVerteilung = false;
	let chartDataVeraenderung = false;
	let headings;

	let innerHeight;

	function getLabelWidth() {
		if ($selectedDataView === 'analyse') {
			return 130;
		} else {
			return $datensatzLabelWidth[$selectedDatensatz] || 120;
		}
	}

	function needsChartDataUpdate() {
		if ($datasetUnits[$selectedDatensatz].indexOf('prozent') === -1 && chartUnit === 'prozent') {
			chartUnit = 'ha';
			chartUnitSymbol = 'ha';
		}

		chartDataVeraenderung = prepairVeraenderung(
			$selectedAdminData,
			$selectedAdminDataYears,
			chartUnit,
			$classTypes,
			$selectedDatensatz
		);

		chartDataVerteilung = prepairVerteilung(
			$selectedAdminData,
			$selectedAdminDataYears,
			chartUnit,
			$classTypes,
			$selectedDatensatz
		);

		chartLabelWidth = getLabelWidth();
	}

	$: {
		chartUnit;
		$selectedAdminData;
		needsChartDataUpdate();
	}

	$: {
		headings = getDatensatzHeadings($selectedDatensatz, $datensaetze);
	}
</script>

<svelte:window bind:innerHeight />

{#if $infoBarVisible}
	<div
		id="info-bar"
		class="rounded-none lg:rounded lg:m-5 p-3 lg:p-0 w-full lg:w-96 absolute bottom-0 lg:top-0 lg:right-0 z-30 pointer-events-none"
		transition:slideFlexibleAxis|local={transitionDirection}
	>
		<div
			class="info-bar-wrapper overflow-hidden bg-white p-5 rounded lg:w-96 max-h-85 lg:max-h-full overflow-y-auto shadow-2xl lg:shadow-none pointer-events-auto border"
			style="height:{innerHeight - 100}px"
		>
			<div class="text-right cursor-pointer icon-close" on:click={() => toggleInfo()} />

			{#if $selectedDataView === 'analyse'}
				<InfobarIndikator bind:chartLabelWidth />
			{/if}

			{#if $selectedDataView === 'veraenderung'}
				<InfobarVeraenderung
					bind:chartDataVeraenderung
					bind:chartUnit
					bind:chartUnitSymbol
					bind:chartLabelWidth
					bind:headings
				/>
			{/if}

			{#if $selectedDataView === 'klassen'}
				<InfobarKlassen
					bind:chartDataVerteilung
					bind:chartUnit
					bind:chartUnitSymbol
					bind:chartLabelWidth
					bind:headings
				/>
			{/if}
		</div>
	</div>
{/if}

<style lang="scss">
</style>

<script>
	import Select from 'svelte-select';
	import { isMobile } from '$lib/stores/storesShared';
	import { mobileSearchVisible } from '$lib/stores/storesDashboard';
	import { fly } from 'svelte/transition';
	import iconSearch from '../expertmode/icons/Search.svelte';
	import Germany from '../expertmode/icons/Germany.svelte';

	import {
		lookupTable,
		selectedAdminId,
		selectedAdminType,
		adminTypes
	} from '$lib/stores/storesDashboard';

	let filterText = '';
	let itemList = undefined;
	let selectedItem = undefined;
	let allowUpdateSelectedItem = true;

	function getSelectItems($selectedAdminType, filterText) {
		if (!filterText) return;
		if (filterText.length < 2) return;

		let ranking = JSON.parse(JSON.stringify($adminTypes));
		ranking[$selectedAdminType].importance = 100;

		const items = [];

		for (const key in $lookupTable) {
			const e = $lookupTable[key];
			if (e.art_gebietseinheit !== 'na') {
				items.push({
					schluessel_gebietseinheit: key,
					label: e.name_gebietseinheit,
					group: e.art_gebietseinheit.toLocaleLowerCase()
				});
			}
		}

		items.sort(function (a, b) {
			return (
				ranking[b.group.toLocaleLowerCase()].importance -
				ranking[a.group.toLocaleLowerCase()].importance
			);
		});

		return items;
	}

	$: {
		itemList = getSelectItems($selectedAdminType, filterText);
	}

	let noOptionsMessage = '';
	$: {
		noOptionsMessage =
			filterText.length <= 1
				? 'Mindestens zwei Buchstaben eingeben'
				: 'Keine Verwaltungseinheit gefunden';
	}

	function onAdminIdChange(selectedAdminId) {
		if (!allowUpdateSelectedItem) return; // do not update selectedItem when it is selcted via the search
		if (!selectedAdminId) {
			selectedItem = null;
			return;
		}
		selectedItem = {
			schluessel_gebietseinheit: selectedAdminId,
			label: $lookupTable[selectedAdminId].name_gebietseinheit,
			group: $selectedAdminType
		};
	}

	$: {
		onAdminIdChange($selectedAdminId);
	}

	const optionIdentifier = 'schluessel_gebietseinheit';
	const groupBy = (item) => item.group;

	function handleSelect(event) {
		allowUpdateSelectedItem = false;
		selectedItem = JSON.parse(JSON.stringify(event.detail));
		$selectedAdminId = selectedItem.schluessel_gebietseinheit;
		$selectedAdminType = selectedItem.group;
		setTimeout(() => {
			allowUpdateSelectedItem = true;
		}, 100);
	}

	function handelClear() {
		$selectedAdminId = null;
	}
</script>

{#if !$isMobile}
	<div class="search w-56 lg:w-72 top-24 lg:top-0 icon">
		<Select
			items={itemList}
			Icon={iconSearch}
			bind:filterText
			bind:noOptionsMessage
			{optionIdentifier}
			{groupBy}
			value={selectedItem}
			placeholder="Verwaltungseinheit suchen …"
			on:select={handleSelect}
			on:clear={handelClear}
		/>
	</div>
{:else}
	<div
		class="lg:mt-5 z-10 absolute left-4 lg:left-100 top-20 lg:top-24"
		on:click={() => ($mobileSearchVisible = !$mobileSearchVisible)}
	>
		<div class="icon-search bg-white rounded cursor-pointer p-4 border" />
	</div>
	{#if $mobileSearchVisible}
		<div
			class="z-10 absolute left-20 top-20 w-60 shadow-lg icon"
			transition:fly|local={{ x: -10, duration: 300 }}
		>
			<Select
				bind:filterText
				bind:noOptionsMessage
				items={itemList}
				{optionIdentifier}
				{groupBy}
				value={selectedItem}
				placeholder="Verwaltungseinheit suchen …"
				on:select={handleSelect}
				on:clear={handelClear}
			/>
		</div>
	{/if}
{/if}

<style lang="scss">
	@import '../../../styles/variables.scss';

	:global(.clearSelect svg) {
		cursor: pointer;
	}

	.themed {
		--groupTitleColor: $color-select;
	}

	:global(.selectContainer) {
		height: 2.5rem !important;
		font-size: 14px !important;
	}

	@media screen and (max-width: 1024px) {
		:global(.selectContainer) {
			height: 3.5rem !important;
		}
	}

	.icon {
		--selectedItemPadding: 0 10px 0 8px;
		--inputPadding: 0 10px 0 40px;
	}
</style>

<script>
	import { datensaetze } from '$lib/stores/storesShared';
	import { selectedDatensatz, expertModeVisible } from '$lib/stores/storesDashboard';
	import { slide } from 'svelte/transition';
	import validateQuery from '$lib/utils/validateQuery';
	import query from 'query-store';
	import tooltip from '$lib/utils/tooltip';

	import { isMobile } from '$lib/stores/storesShared';
	let accordeonState = {};

	function setupNav() {
		const selectedD = validateQuery($query.mdatensatz, {
			type: 'isSpecificValue',
			fallback: $selectedDatensatz,
			validValues: Object.values($datensaetze)
				.map(function (d) {
					return Object.keys(d.subDatensaetze);
				})
				.flat()
		});

		Object.keys($datensaetze).forEach((d) => {
			accordeonState[d] = {};
			accordeonState[d].visible =
				Object.keys($datensaetze[d].subDatensaetze).indexOf(selectedD) !== -1;
		});
	}

	function onSelect(d) {
		$selectedDatensatz = d;
		$query.mdatensatz = d;
	}

	function openAccordeon(d) {
		accordeonState[d].visible = !accordeonState[d].visible;
	}

	function updateNav() {
		setupNav();
	}

	$: {
		$expertModeVisible;
		updateNav();
	}
</script>

<div class="p-4 lg:p-0">
	{#each Object.values($datensaetze) as sublist, i}
		<div class="mb-2 rounded bg-gray-200 hover:bg-gray-300 noselect">
			<div
				class="p-2 cursor-pointer pl-4 flex justify-between"
				class:open={accordeonState[Object.keys($datensaetze)[i]].visible}
				on:click={openAccordeon(Object.keys($datensaetze)[i])}
			>
				<span>{sublist.label}</span>

				<div
					class="icon-chevron-right transition-transform duration-300 {accordeonState[
						Object.keys($datensaetze)[i]
					].visible
						? 'rotate'
						: ''}"
				/>
			</div>

			{#if accordeonState[Object.keys($datensaetze)[i]].visible}
				<div transition:slide|local={{ duration: 300 }} class="pb-2">
					{#each Object.values(sublist.subDatensaetze) as sub, i}
						<div class="p-2 pl-8 cursor-pointer noselect">
							<span
								class="hover:text-select"
								class:active={Object.keys(sublist.subDatensaetze)[i] === $selectedDatensatz}
								on:click={onSelect(Object.keys(sublist.subDatensaetze)[i])}>{sub.label}</span
							>
							<a
								use:tooltip={{
									content: 'Weitere Informationen zum Datensatz',
									placement: 'right'
								}}
								href="/daten/{Object.keys(sublist.subDatensaetze)[i]}"
								class="icon-info hover:text-white"
								on:click={onSelect(Object.keys(sublist.subDatensaetze)[i])}
							/>
						</div>
					{/each}
				</div>
			{/if}
		</div>
	{/each}

	{#if !$isMobile}
		<button
			class="p-1 px-3 mt-2 text-center rounded bg-white hover:bg-select text-text hover:text-white border hover:border-select"
			on:click={() => ($expertModeVisible = true)}
			><span class="icon-exchange pr-1" />Datensätze vergleichen
		</button>
	{/if}
</div>

<style lang="scss">
	@import '../../../styles/variables.scss';

	.active {
		color: $color-select;
		font-weight: 700;
	}

	.open {
		font-weight: 700;
	}

	.rotate {
		transform: rotate(90deg);
	}

	.noselect {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	}
</style>

import { writable, readable, derived } from 'svelte/store';


export let selectedDatensatzCompared = writable('europe-clc');
export let selectedAdminDataCompared = writable(false);
export let indicatorDataCompared = writable(false)

// the start and end year of the data as array [start,end]
export const selectedAdminDataYearsCompared = derived([selectedAdminDataCompared], ([$selectedAdminDataCompared]) => {
	const keys = Object.keys($selectedAdminDataCompared).filter((key) => key.includes('class_'));
    
	if (keys.length) {
		return [keys[0].split('_')[1], keys[1].split('_')[1]];
	} else {
		return [0, 0];
	}
});
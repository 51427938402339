<script>
	let disclaimerVisible = true;
	import { slide } from 'svelte/transition';
</script>

{#if disclaimerVisible}
	<div
		class="absolute bottom-0 left-0 w-full bg-select text-white text-sm z-50 border-b rounded-t px-12 py-6"
		transition:slide|local={{ duration: 300 }}
	>
		<span
			class="icon-close absolute right-3 top-3 cursor-pointer"
			on:click={() => {
				disclaimerVisible = !disclaimerVisible;
			}}
		/>
		<div>
			<span class="font-bold">Nutzungshinweise und Anmerkungen zur Interpretation:</span><br />Der
			Vergleich von Datensätzen dient dazu, unterschiedliche Werteausprägungen von Indikatoren der
			Flächenentwicklung besser zu verstehen. Verfügbare Datensätze decken nicht immer den gleichen
			Zeitraum ab und unterliegen technisch begründeten Abweichungen in der Detektion von
			Landnutzungs- und Landbedeckungsklassen. Die Bedeutung dieser Unterschiede kann hier durch die
			Visualisierung in Karten und Diagrammen interaktiv untersucht werden. Wählen Sie hierfür in
			der Karte links und rechts jeweils eine Gebietseinheit, den gewünschten Datensatz und einen
			Indikator für den Vergleich aus. Es ist vorgesehen, zukünftige Fortschreibungen und weitere
			Datensätze für diese Vergleichbarkeit zu integrieren.
		</div>
	</div>
{/if}

<script>
	import { adminTypes, selectedAdminType, selectedAdminId } from '$lib/stores/storesDashboard';

	function onSelect(selected) {
		if ($selectedAdminType === selected) return;
		$selectedAdminType = selected;
		$selectedAdminId = null;
	}
</script>

<div class="space-y-2 p-4 lg:p-0">
	{#each Object.values($adminTypes) as sublist, i}
		<div
			class="bg-gray-200 p-2 rounded cursor-pointer text-center hover:bg-gray-300"
			class:active={Object.keys($adminTypes)[i] === $selectedAdminType}
			on:click={onSelect(Object.keys($adminTypes)[i])}
		>
			{sublist.label}
		</div>
	{/each}
</div>

<style lang="scss">
	@import '../../../styles/variables.scss';

	.active {
		background-color: $color-select;
		color: white;
	}
</style>
